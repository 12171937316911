import React from 'react';
import './style.scss';

type TypeProps = React.HTMLAttributes<HTMLDivElement> & {
    children?: React.ReactNode;
};

const index = ({ children, ...props }: TypeProps) => {
    return (
        <span {...props} className="ui-sub-text">
            ({children})
        </span>
    );
};

export default index;
