import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SettingsPage from 'src/pages/settings/SettingsPage';
import LayoutSideBar from './LayoutSideBar';
import HomePage from '../pages/home/HomePage';
import RegisterPage from '../pages/register/RegisterPage';
import ProfileRegisterPage from '../pages/profile-register/ProfileRegisterPage';
import CommunityPage from '../pages/community/CommunityPage';
import ProfilePage from '../pages/profile/ProfilePage';
import SearchProfilePage from '../pages/search-profile/SearchProfilePage';
import CancelAccount from '../pages/cancel-account/CancelAccount';
import CancelAccountSuccessPage from '../pages/cancel-account-success/CancelAccountSuccessPage';
import AcceptRemovePage from '../pages/accept-remove/AcceptRemovePage';
import CancelBackPage from '../pages/cancel-back/CancelBackPage';
import CancelSuccessPage from '../pages/accept-success/CancelSuccessPage';
import TodayNewsPage from '../pages/today-news/TodayNewsPage';
import FollowUpPage from '../pages/follow-up/FollowUpPage';
import ForgotPasswordPage from '../pages/forgot-password/ForgotPasswordPage';

const AppRouter = () => {
    return (
        <React.Fragment>
            <Routes>
                <Route
                    path="/"
                    element={
                        <LayoutSideBar>
                            <HomePage />
                        </LayoutSideBar>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <LayoutSideBar>
                            <SettingsPage />
                        </LayoutSideBar>
                    }
                />
                <Route
                    path="/change-profile"
                    element={
                        <LayoutSideBar>
                            <ProfileRegisterPage />
                        </LayoutSideBar>
                    }
                />
                <Route
                    path="/search-profile"
                    element={
                        <LayoutSideBar>
                            <SearchProfilePage />
                        </LayoutSideBar>
                    }
                />
                {/* <Route
                                path="/ranks-king"
                                element={
                                    <LayoutSideBar>
                                        <RankPage />
                                    </LayoutSideBar>
                                }
                            /> */}
                <Route
                    path="/community"
                    element={
                        <LayoutSideBar>
                            <CommunityPage />
                        </LayoutSideBar>
                    }
                />
                <Route
                    path="/today-news"
                    element={
                        <LayoutSideBar>
                            <TodayNewsPage />
                        </LayoutSideBar>
                    }
                />

                <Route path="/register/success" element={<RegisterPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/login" element={<RegisterPage />} />
                <Route path="/account/remove" element={<CancelAccount />} />
                <Route path="/account/accept-remove" element={<AcceptRemovePage />} />
                <Route path="/account/success" element={<CancelAccountSuccessPage />} />
                <Route path="/account/cancel-back" element={<CancelBackPage />} />
                <Route path="/account/accept-success" element={<CancelSuccessPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route
                    path="/follow-up"
                    element={
                        <LayoutSideBar>
                            <FollowUpPage />
                        </LayoutSideBar>
                    }
                />
                <Route
                    path="*"
                    element={
                        <LayoutSideBar>
                            <ProfilePage />
                        </LayoutSideBar>
                    }
                />
            </Routes>
        </React.Fragment>
    );
};

export default AppRouter;
