// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accept-remove-page-page-logo {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.accept-remove-page-page-logo img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.accept-remove-page-page {
  height: 100%;
  min-height: 100px;
  width: 100%;
  max-width: 360px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 2px 1px rgba(128, 128, 128, 0.37);
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  color: rgb(37, 37, 37);
}
.accept-remove-page-page .accept-remove-page-title {
  text-align: center;
}

@media only screen and (max-width: 792px) {
  .accept-remove-page-page {
    height: 100%;
    min-height: 150px;
    width: 100%;
    max-width: 320px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 0px 2px 1px rgba(128, 128, 128, 0.37);
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/accept-remove/AcceptRemovePage.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,cAAA;AACJ;AAEI;EACI,WAAA;EACA,iBAAA;EACA,iBAAA;AAAR;;AAGA;EACI,YAAA;EACA,iBAAA;EACA,WAAA;EACA,gBAAA;EACA,uBAAA;EACA,kBAAA;EACA,qDAAA;EACA,cAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,sBAAA;AAAJ;AACI;EACI,kBAAA;AACR;;AAEA;EACI;IACI,YAAA;IACA,iBAAA;IACA,WAAA;IACA,gBAAA;IACA,uBAAA;IACA,kBAAA;IACA,qDAAA;IACA,cAAA;IACA,aAAA;IACA,aAAA;IACA,sBAAA;EACN;AACF","sourcesContent":[".accept-remove-page-page-logo {\n    width: 100%;\n    max-width: 400px;\n    margin: 0 auto;\n    // padding-top: 40px;\n    // padding-bottom: 20px;\n    img {\n        width: 100%;\n        max-height: 200px;\n        object-fit: cover;\n    }\n}\n.accept-remove-page-page {\n    height: 100%;\n    min-height: 100px;\n    width: 100%;\n    max-width: 360px;\n    background-color: white;\n    border-radius: 4px;\n    box-shadow: 0px 0px 2px 1px rgba(128, 128, 128, 0.37);\n    margin: 0 auto;\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    color: rgb(37, 37, 37);\n    .accept-remove-page-title {\n        text-align: center;\n    }\n}\n@media only screen and (max-width: 792px) {\n    .accept-remove-page-page {\n        height: 100%;\n        min-height: 150px;\n        width: 100%;\n        max-width: calc(360px - 40px);\n        background-color: white;\n        border-radius: 4px;\n        box-shadow: 0px 0px 2px 1px rgba(128, 128, 128, 0.37);\n        margin: 0 auto;\n        padding: 20px;\n        display: flex;\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
