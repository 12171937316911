// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-note-text {
  font-size: 12px;
  color: grey;
  margin-right: 3px;
}`, "",{"version":3,"sources":["webpack://./src/component/NoteText/style.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,WAAA;EACA,iBAAA;AACJ","sourcesContent":[".ui-note-text{\n    font-size: 12px;\n    color: grey;\n    margin-right: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
