import React from 'react';
import { Form, Input, PageTitle } from '../../../component';
import { REGEX_PASSWORD } from '../../../constants/regex';
import { changePasswordAPI } from '../../../services/graphql/change-password';
import { notification } from 'antd';

const ChangePassword = props => {
    const [loading, setLoading] = React.useState(false);
    const [messageApi, contextHolder] = notification.useNotification();
    const [errorMessage, setErrorMessage] = React.useState({
        oldPassword: null,
        newPassword: null,
        reNewPassword: null,
    });
    const [dataText, setDataText] = React.useState({
        oldPassword: null,
        newPassword: null,
        reNewPassword: null,
    });

    const onChange = e => {
        const { name, value } = e.target;
        setDataText({
            ...dataText,
            [name]: value,
        });
        setErrorMessage({
            oldPassword: null,
            newPassword: null,
            reNewPassword: null,
        });
    };

    const handleChangePassword = async e => {
        const { oldPassword, newPassword, reNewPassword } = e;

        if (!oldPassword || oldPassword.trim().length < 1) {
            setErrorMessage({
                ...errorMessage,
                oldPassword: '現在のパスワードを入力してください',
            });
        } else if (oldPassword.trim().length < 6) {
            setErrorMessage({
                ...errorMessage,
                oldPassword: 'パスワードは少なくとも6文字でなければなりません',
            });
        } else if (!REGEX_PASSWORD.test(oldPassword)) {
            setErrorMessage({
                ...errorMessage,
                oldPassword:
                    'パスワードはフォーマットされていません。A-Za-z0-9.!,?*%$#@ の範囲の文字を使用してください。',
            });
        } else if (!newPassword || newPassword.trim().length < 1) {
            setErrorMessage({
                ...errorMessage,
                newPassword: '新しいパスワードを入力してください',
            });
        } else if (newPassword.trim().length < 6) {
            setErrorMessage({
                ...errorMessage,
                newPassword: '新しいパスワードは 6 文字以上にする必要があります',
            });
        } else if (!REGEX_PASSWORD.test(newPassword)) {
            setErrorMessage({
                ...errorMessage,
                newPassword:
                    'パスワードはフォーマットされていません。A-Za-z0-9.!,?*%$#@ の範囲の文字を使用してください。',
            });
        } else if (newPassword.trim() === oldPassword) {
            setErrorMessage({
                ...errorMessage,
                newPassword: '新しいパスワードは現在のパスワードを使用できません',
            });
        } else if (!reNewPassword || reNewPassword.trim().length < 1) {
            setErrorMessage({
                ...errorMessage,
                reNewPassword: '再入力するパスワードは空白にできません',
            });
        } else if (!REGEX_PASSWORD.test(reNewPassword) || reNewPassword !== newPassword) {
            setErrorMessage({
                ...errorMessage,
                reNewPassword: '再入力したパスワードは新しいパスワードと同じではありません',
            });
        } else {
            setLoading(true);
            const result = await changePasswordAPI({ oldPassword, newPassword });
            if (result && result.status === '200') {
                messageApi.success({
                    message: 'メール更新成功',
                    placement: 'bottomRight',
                });
                setDataText({
                    oldPassword: null,
                    newPassword: null,
                    reNewPassword: null,
                });
                setLoading(false);
            } else {
                messageApi.error({
                    message: result && result.mess ? result.mess : 'サーバーエラー',
                    placement: 'bottomRight',
                });
                setLoading(false);
            }
        }
    };
    return (
        <React.Fragment>
            {contextHolder}
            <PageTitle title="パスワード変更" />
            <br />
            <Form
                buttonEvent={handleChangePassword}
                disabledButton={loading}
                // subTitle="登録 し た パス ワー ド を 変更 され る 方 は 、 新しい パス ワー ド を 入力 し て くだ さい 。"
                content={
                    <React.Fragment>
                        <br />
                        <Input
                            onChange={onChange}
                            error={errorMessage.oldPassword}
                            errorText={errorMessage.oldPassword}
                            title="現在のパスワード"
                            tick="必須"
                            name="oldPassword"
                            type='password'
                        />
                        <br />
                        <Input
                            onChange={onChange}
                            error={errorMessage.newPassword}
                            errorText={errorMessage.newPassword}
                            title="新しいパスワード"
                            tick="必須"
                            name="newPassword"
                            type='password'
                        />
                        <br />
                        <Input
                            onChange={onChange}
                            error={errorMessage.reNewPassword}
                            errorText={errorMessage.reNewPassword}
                            title="新しいパスワード(確認)"
                            tick="必須"
                            name="reNewPassword"
                            type='password'
                        />
                    </React.Fragment>
                }
                title="ご登録のパスワードを変更することができます。"
                buttonTitle="変更"
            />
        </React.Fragment>
    );
};

export default ChangePassword;
