import React, { useEffect } from 'react';
import { Button, Form, Input, Notification, PageTitle } from '../../../component';
import { Modal, notification, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PayJPPage from '../../register/PayJPPage';
import reState from '../../../hooks/ReState';
import { updateCardPaymentAPI } from '../../../services/graphql/card';
import getStripe from '../../register/getStripe';
import LoadInfoImg from '../../../assets/load-info.png';
import defineMessage from '../../../helper/defineErrorMessage';

const ChangePayment = props => {
    const [openModal, setOpenModal] = React.useState(false);
    const [openModalPayJp, setOpenModalPayJp] = React.useState(false);
    const { profile, setProfile, stripeErrorPayment } = React.useContext(reState);
    const [stripe, setStripe] = React.useState('');
    const [messageApi, contextHolder] = notification.useNotification();
    const [dataStripe, setDataStripe] = React.useState({
        brand: '',
        expMonth: '',
        expYear: '',
        last4: '',
    });
    const [errorSign, setErrorSign] = React.useState({
        error: '',
    });
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    useEffect(() => {
        if (!stripe && errorSign.error.length) {
            setOpenModal(false);
            messageApi.error({
                message: errorSign.error,
                placement: 'bottomRight',
            });
        }
        // eslint-disable-next-line
    }, [errorSign]);

    const onChangeCard = async () => {
        //updateCardPaymentAPI
        setOpenModal(true);
        if (!stripe || !profile) {
            setOpenModal(false);
            messageApi.error({
                message: '新しいカードの入力を完了してください',
                placement: 'bottomRight',
            });
        } else {
            const result = await updateCardPaymentAPI({
                updateCardId: profile.id,
                brand: dataStripe.brand,
                expMonth: dataStripe.expMonth,
                expYear: dataStripe.expYear,
                last4: dataStripe.last4,
                stripe: stripe,
            });

            if (result && result.status === '200') {
                const stripeApi = await getStripe();
                if (result.need_3d_authen) {
                    const { error } = await stripeApi.confirmCardSetup(result.client_secret);
                    if (error) {
                        setOpenModal(false);
                        messageApi.error({
                            message: defineMessage(error),
                            placement: 'bottomRight',
                        });
                        return;
                    } else {
                        const response = await updateCardPaymentAPI({
                            updateCardId: profile.id,
                            brand: dataStripe.brand,
                            expMonth: dataStripe.expMonth,
                            expYear: dataStripe.expYear,
                            last4: dataStripe.last4,
                            stripe: stripe,
                            authenticate_success: true,
                        });

                        if (response && response.status === '200') {
                            messageApi.success({
                                message: 'カードを正常に変更',
                                placement: 'bottomRight',
                            });
                            setProfile({
                                ...profile,
                                payment: {
                                    ...profile.payment,
                                    last4: dataStripe.last4,
                                },
                            });
                            setOpenModal(false);
                            return;
                        } else {
                            setOpenModal(false);
                            messageApi.error({
                                message: response.mess,
                                placement: 'bottomRight',
                            });
                            return;
                        }
                    }
                }

                messageApi.success({
                    message: 'カードを正常に変更',
                    placement: 'bottomRight',
                });
                setProfile({
                    ...profile,
                    payment: {
                        ...profile.payment,
                        last4: dataStripe.last4,
                    },
                });
                setOpenModal(false);
            } else {
                setOpenModal(false);
                messageApi.error({
                    message: result.mess,
                    placement: 'bottomRight',
                });
            }
        }
    };
    return (
        <React.Fragment>
            {contextHolder}
            <Modal
                onOk={() => setOpenModalPayJp(false)}
                onCancel={() => setOpenModalPayJp(false)}
                title=""
                centered
                open={openModalPayJp}
                closable={false}
                footer={null}>
                <PayJPPage {...{ setStripe, setOpenModalPayJp, setDataStripe, setErrorSign }} />
            </Modal>
            <Modal
                onOk={() => {}}
                onCancel={() => {}}
                title=""
                centered
                open={openModal}
                closable={false}
                footer={null}>
                <div className="modal-img">
                    <img alt="画像" src={LoadInfoImg} />
                </div>
                <h1 className="modal-text">更新中です。</h1>
                <h4 className="modal-text">このまましばらくお待ちください。</h4>
                <h4 className="modal-text">
                    <Spin indicator={antIcon} />
                </h4>
            </Modal>
            <PageTitle title="お支払い情報変更" />
            {stripeErrorPayment && (
                <>
                    <br />
                    <Notification
                        title="決済エラーの場合"
                        content={
                            <React.Fragment>
                                決済エラーの場合、ご利用可能なお支払い情報へ更新されますと自動的に決済が再開されます。
                            </React.Fragment>
                        }
                        line="default"
                        type="red"
                    />
                </>
            )}
            <br />
            <Form
                // disabledButton={!stripe}
                // buttonEvent={onChangeCard}
                buttonEvent={() => {
                    window.open('https://billing.stripe.com/p/login/eVafZa5Amd484BG5kk', '_blank');
                }}
                // subTitle="登録 し た クレ ジッ トカ ー ド 情報 を 変更 され る 方 は 、 新 し い ク レジ ッ ト カ ー ド 情報 を 入力 し て くだ さい 。"
                content={
                    <React.Fragment>
                        <br />
                        <Input
                            disabled
                            title="現在のクレジットカード番号"
                            value={`**** **** **** ${
                                profile && profile.payment && profile.payment.last4 ? profile.payment.last4 : '****'
                            }`}
                        />
                        <br />
                        {/* <Button onClick={() => setOpenModalPayJp(true)} title="カード情報を入力する" theme="info" /> */}
                    </React.Fragment>
                }
                title="ご登録のクレジットカードを変更することができます。"
                // buttonTitle="変更"
                buttonTitle="Stripe サイトにアクセスする"
            />
        </React.Fragment>
    );
};

export default ChangePayment;
