import React from 'react';
import { Button } from '../../component';
import { useNavigate } from 'react-router-dom';
import reState from '../../hooks/ReState';

const CancelBackPage = () => {
    const navigate = useNavigate();
    const {profile} = React.useContext(reState)
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', justifyContent: 'center', height: '90vh' }}>
            <div style={{ color: 'red', fontSize: 30, fontWeight: '700' }}>決済エラーがあります</div>
            <br />
            <div style={{borderRadius: '4px', border: '2px solid red', padding: '10px 30px'}}>
                ご利用料金滞納があるため解約手続きに進ません。
                <br />
                至急、ご利用料金をお支払いください。
            </div>
            <br />
            <Button onClick={() => navigate(profile ? "/change-profile" : '/login')} title="お支払い情報変更はこちらより" />
        </div>
    );
};

export default CancelBackPage;
