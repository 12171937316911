import React, { useEffect, useState } from 'react';
import { BsChatFill } from 'react-icons/bs';
import { FaLock } from 'react-icons/fa';
import { Button, Input, Notification } from '../../component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './AcceptRemovePage.scss';
import Logo from '../../assets/logo.png';
import NotFound from '../profile/NotFound';
import { REGEX_PASSWORD } from '../../constants/regex';
import { acceptRemoveAccount, checkTokenRemoveAccount } from '../../services/graphql/account';
import reState from '../../hooks/ReState';

const AcceptRemovePage = () => {
    const route = useLocation();
    const navigate = useNavigate();
    const defaultData = {
        account: '',
        password: '',
        error: '',
    };
    const [errorText, setErrorText] = useState(defaultData);
    const [data, setData] = useState(defaultData);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const { profile } = React.useContext(reState);

    useEffect(() => {
        if (route.search) {
            setToken(route.search.replace('?key=', '') || '');
        } else {
            setToken('');
        }
        // eslint-disable-next-line
    }, [route]);

    const onChange = e => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
        setErrorText(defaultData);
    };

    useEffect(() => {
        if (token) {
            const callApi = async () => {
                const result = await checkTokenRemoveAccount({ token });
                if (result && result.status !== '200') {
                    if (result.status === 'ERROR_1015') {
                        navigate('/account/cancel-back');
                    } else {
                        setErrorText({
                            ...errorText,
                            error: result.mess,
                        });
                    }
                }
            };
            callApi();
        }
        // eslint-disable-next-line
    }, [token]);

    React.useEffect(() => {
        document.body.style.overflowY = 'auto';
        document.body.style.paddingBottom = '70px';
        // eslint-disable-next-line
    }, []);

    const onSubmit = async () => {
        const { account, password } = data;
        if (!account || account.trim().length < 1)
            setErrorText({
                ...errorText,
                account: 'アカウントのキャンセル理由を入力してください',
            });
        else if (!password || password.trim().length < 1)
            setErrorText({
                ...errorText,
                password: 'パスワードを空白にすることはできません',
            });
        else if (!REGEX_PASSWORD.test(password) || password.length < 6) {
            setErrorText({
                ...errorText,
                password: 'パスワードの形式が正しくありません',
            });
        } else {
            setLoading(true);
            const result = await acceptRemoveAccount({ account, password, token });

            if (result.status === '200') {
                setLoading(false);
                navigate('/account/accept-success');
            } else if (result.status === 'ERROR_1015') {
                navigate('/account/cancel-back');
            } else {
                setErrorText({
                    ...errorText,
                    error: result.mess,
                });
                setLoading(false);
            }
        }
    };
    return (
        <React.Fragment>
            {token ? (
                <>
                    <br />
                    <br />
                    <div className="accept-remove-page-page-logo">
                        <img alt="画像" src={Logo} />
                    </div>
                    <div className="accept-remove-page-page">
                        <div className="accept-remove-page-title">
                            ご解約手続きを完了させるため、insta-styleにご登録のパスワードをご入力ください。
                        </div>
                        <br />
                        {errorText.error && (
                            <>
                                <Notification type="red" titleString={errorText.error} theme="error" line="default" />
                                <br />
                            </>
                        )}
                        <Input
                            name="account"
                            onChange={onChange}
                            icon={<BsChatFill />}
                            placeholder="解約理由"
                            error={errorText.account}
                            errorText={errorText.account}
                            value={data.account}
                        />
                        <br />
                        <Input
                            name="password"
                            onChange={onChange}
                            icon={<FaLock />}
                            placeholder="ご登録のパスワード"
                            error={errorText.password}
                            errorText={errorText.password}
                            value={data.password}
                            type="password"
                        />
                        <br />
                        <Button onClick={onSubmit} disabled={loading} title="解約" style={{ width: '100%' }} />
                        <br />
                        <Link
                            style={{
                                color: 'rgb(255 150 206)',
                                textAlign: 'center',
                                textDecoration: 'none',
                                fontWeight: '600',
                                fontSize: '18px',
                            }}
                            to={profile ? '/' : '/login'}>
                            マイページ
                        </Link>
                    </div>
                </>
            ) : (
                <NotFound />
            )}
        </React.Fragment>
    );
};

export default AcceptRemovePage;
