import { ApolloClient, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
    uri: `${process.env.REACT_APP_GRAPHQL_URL || 'http://43.207.129.64:3100/'}graphql`,
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
        mutate: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    },
    headers: {
        Authorization: `${
            localStorage.getItem('__ft')
                ? localStorage.getItem('__ft')
                : sessionStorage.getItem('__at') ?? process.env.REACT_APP_GRAPHQL_URL
        }`,
    },
});

export default client;
