// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-profile-50 {
  width: calc(50% - 10px);
}

.search-profile-100 {
  display: flex;
  gap: 20px;
}

@media only screen and (max-width: 792px) {
  .search-profile-50 {
    width: 100%;
  }
  .search-profile-100 {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
}
.page-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/search-profile/SearchProfilePage.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;;AACA;EACI,aAAA;EACA,SAAA;AAEJ;;AAAA;EACI;IACI,WAAA;EAGN;EADE;IACI,aAAA;IACA,SAAA;IACA,sBAAA;EAGN;AACF;AADA;EACI,WAAA;EACA,aAAA;EACA,uBAAA;AAGJ","sourcesContent":[".search-profile-50 {\n    width: calc(50% - 10px);\n}\n.search-profile-100 {\n    display: flex;\n    gap: 20px;\n}\n@media only screen and (max-width: 792px) {\n    .search-profile-50 {\n        width: 100%;\n    }\n    .search-profile-100 {\n        display: flex;\n        gap: 20px;\n        flex-direction: column;\n    }\n}\n.page-pagination{\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
