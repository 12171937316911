// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-sub-text {
  font-size: 13px;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/component/SubText/style.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;AACJ","sourcesContent":[".ui-sub-text{\n    font-size: 13px;\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
