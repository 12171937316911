import React from 'react';
import { SmileOutlined } from '@ant-design/icons';
import { Result } from 'antd';

import { Notification, PageTitle, Skeleton } from '../../component';
import ProfileItem from '../search-profile/ProfileItem';
import { profileAPI } from '../../services/graphql/profile';
import { convertSnsProfile } from '../../helper/profile';
import reState from '../../hooks/ReState';
import { DEFAULT_MAX_LIMIT } from '../../constants/constants';

import './CommunityPage.scss';

const CommunityPage = () => {
    const { listProfileCommunity, setlistProfileCommunity } = React.useContext(reState);
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        if (!listProfileCommunity) {
            const callAPI = async () => {
                setLoading(true);
                const result = await profileAPI({ size: DEFAULT_MAX_LIMIT, random: true, up: true });
                if (result && result.status === '200') {
                    setlistProfileCommunity(result.resul);
                }
                setLoading(false);
            };
            callAPI();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <React.Fragment>
            <PageTitle title="フォローUP" />
            <br />
            <Notification
                // title="フォローUPとは？"
                content={
                    <React.Fragment>
                        AIがあなたにおすすめのユーザーを紹介します。
                        <br/>
                        積極的にアクションしてフォロワーを増やしましょう
                    </React.Fragment>
                }
            />
            <br />
            <div className="community-page">
                {listProfileCommunity && listProfileCommunity.length && !loading
                    ? listProfileCommunity?.map((profile, key) => (
                          <ProfileItem {...{ key, profile: convertSnsProfile(profile) }} />
                      ))
                    : ''}
                {loading ? (
                    Array(6)
                        .fill('')
                        .map(i => <Skeleton key={i} className="profile-item" style={{ height: '150px' }} />)
                ) : listProfileCommunity && listProfileCommunity.length ? (
                    ''
                ) : (
                    <Result icon={<SmileOutlined />} title="結果が見つかりません" />
                )}
            </div>
        </React.Fragment>
    );
};

export default CommunityPage;
