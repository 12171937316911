import React from 'react';
import './style.scss';
import { FormNoteText, FormSubText, FormTick, FormTitle } from '..';
import { Select } from 'antd';
import { IoMdArrowDropdown } from 'react-icons/io';
import useWidth from '../../hooks/useWidth';

type TypeProps = {
    title?: React.ReactNode;
    subText?: string;
    tick?: string;
    noteText?: string;
    onChange?: (arg?: any) => any;
    defaultValue?: any;
    value?: any;
    options?: {
        value?: any;
        label?: React.ReactNode;
        disabled?: boolean;
        [key: string]: any;
    }[];
    disabled?: boolean;
    placeholder?: string;
    errorText?: React.ReactNode;
    mode?: 'multiple' | 'default';
    showSearch?: boolean;
};

const SelectForm = ({
    title = '',
    tick = '',
    subText = '',
    noteText = '',
    onChange = () => {},
    defaultValue,
    value,
    options = [],
    disabled = false,
    placeholder = '',
    errorText = '',
    mode = 'default',
    showSearch = false,
}: TypeProps) => {
    const { width } = useWidth();
    return (
        <React.Fragment>
            {title && (
                <div style={{ marginBottom: '10px' }}>
                    <FormTitle>{title}</FormTitle>
                    {subText && <FormSubText>{subText}</FormSubText>}
                    {tick && <FormTick>{tick}</FormTick>}
                </div>
            )}
            <div className="select">
                {mode === 'multiple' ? (
                    <Select
                        value={value}
                        suffixIcon={<IoMdArrowDropdown />}
                        defaultValue={defaultValue}
                        style={{ width: '100%' }}
                        onChange={onChange}
                        options={options}
                        disabled={disabled}
                        placeholder={placeholder}
                        mode={mode}
                        showSearch={Number(width) < 520 ? false : showSearch}
                    />
                ) : (
                    <Select
                        value={value}
                        suffixIcon={<IoMdArrowDropdown />}
                        defaultValue={defaultValue}
                        style={{ width: '100%' }}
                        onChange={onChange}
                        options={options}
                        disabled={disabled}
                        placeholder={placeholder}
                        showSearch={Number(width) < 520 ? false : showSearch}
                    />
                )}
            </div>
            {noteText && (
                <div>
                    <FormNoteText>{noteText}</FormNoteText>
                </div>
            )}
            {errorText && <div className="input-error-text">{errorText}</div>}
        </React.Fragment>
    );
};

export default SelectForm;
