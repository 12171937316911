import React from 'react';
import './style.scss';
import { FormNoteText, FormSubText, FormTick, FormTitle } from '..';

type TypeProps = {
    high?: boolean;
    error?: boolean;
    color?: 'black' | 'green';
    placeholder?: string;
    name?: string;
    title?: any;
    subText?: string;
    tick?: string;
    noteText?: React.ReactNode;
    defaultValue?: any;
    type?: 'text' | 'button' | 'area' | 'password';
    icon?: React.ReactNode;
    onChange?: (value?: any) => void;
    errorText?: React.ReactNode;
    disabled: boolean;
    value?: any;
    maxLength?: number
};

const index = ({
    high = false,
    error = false,
    color = 'black',
    placeholder = '',
    name = '',
    title = '',
    tick = '',
    subText = '',
    noteText = '',
    defaultValue = '',
    type = 'text',
    icon = '',
    onChange = () => {},
    errorText = '',
    disabled = false,
    value = null,
    maxLength = 5000
}: TypeProps) => {
    return (
        <React.Fragment>
            {title && (
                <div style={{ marginBottom: '10px' }}>
                    <FormTitle>{title}</FormTitle>
                    {subText && <FormSubText>{subText}</FormSubText>}
                    {tick && <FormTick>{tick}</FormTick>}
                </div>
            )}
            <div className={`input ${type === 'area' && 'input-area'}`}>
                {type === 'area' ? (
                    <textarea
                        className={`input-item ${error && 'input-error'}  ${
                            !high && 'input-item-borderRadius'
                        } input-item-${color}`}
                        placeholder={placeholder}
                        name={name}
                        defaultValue={defaultValue}
                        value={value}
                        onChange={onChange}></textarea>
                ) : (
                    <React.Fragment>
                        {high && <div className="input-high">@</div>}
                        <input
                            disabled={disabled}
                            className={`input-item ${error && 'input-error'}  ${
                                !high && 'input-item-borderRadius'
                            } input-item-${color}`}
                            placeholder={placeholder}
                            name={name}
                            type={type}
                            value={value}
                            defaultValue={defaultValue}
                            onChange={onChange}
                            maxLength={maxLength}
                            onPaste={onChange}
                        />
                    </React.Fragment>
                )}
                {icon && <div className="input-icon">{icon}</div>}
            </div>
            {noteText && (
                <div>
                    <FormNoteText>{noteText}</FormNoteText>
                </div>
            )}
            {errorText && <div className="input-error-text">{errorText}</div>}
        </React.Fragment>
    );
};

export default index;
