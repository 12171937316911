import {
    FaAddressCard,
    FaMoneyBill,
    // FaHeartbeat,
    // FaCrown,
    FaHome,
    FaIdCard,
    FaSearch,
    FaUserPlus,
    // FaTachometerAlt,
    // FaTicketAlt,
    FaUsers,
} from 'react-icons/fa';

import {BsFillQuestionCircleFill} from 'react-icons/bs'

import { MdContactMail } from 'react-icons/md';

export const menuType = {
    MAIN: 'main',
    ITEM: 'item',
};

export const menuListBar = [
    {
        title: 'メニュー',
        icon: '',
        type: 'main',
        to: '/',
    },
    {
        title: 'フォローUP',
        icon: <FaUsers />,
        type: 'item',
        to: '/community',
        checked: true,
        className: 'menu-up'
    },
    {
        title: 'ピックアップ',
        icon: <FaUserPlus />,
        type: 'item',
        to: '/today-news',
        checked: true,
    },
    {
        title: 'ユーザー検索',
        icon: <FaSearch />,
        type: 'item',
        to: '/search-profile',
        checked: true,
    },
    
    {
        title: 'プロフィール設定',
        icon: <FaAddressCard />,
        type: 'item',
        to: '/settings',
        validateStripe: true,
    },
    // {
    //     title: 'ランクアップバス',
    //     icon: <FaTicketAlt />,
    //     type: 'item',
    //     to: '/rank-profile',
    // },
    // {
    //     title: 'ランキング',
    //     icon: <FaCrown />,
    //     type: 'item',
    //     to: '/ranks-king',
    //     checked: true
    // },
    {
        title: '登録情報',
        icon: <FaIdCard />,
        type: 'item',
        to: '/change-profile',
        // checked: true,
    },
    {
        title: 'サポート',
        icon: '',
        type: 'main',
        to: '/',
    },
    // {
    //     title: 'ヘルプ',
    //     icon: <FaHeadset />,
    //     type: 'item',
    //     to: 'https://3lab.sakura.ne.jp/chatgpt/faq',
    //     target: '_blank',
    // },
    {
        title: 'よくある質問',
        icon: <BsFillQuestionCircleFill />,
        type: 'item',
        to: 'https://tayori.com/q/insta-style/',
        target: '_blank',
    },
    {
        title: 'お問い合わせ',
        icon: <MdContactMail />,
        type: 'item',
        to: 'https://tayori.com/form/c86d05aed625a455881c0fe055c7f7ecef32b432/',
        target: '_blank',
    },
    {
        title: <span style={{ fontSize: '15px' }}>解約について</span>,
        icon: <FaMoneyBill />,
        type: 'item',
        to: '/account/remove',
    },
    {
        title: (
            <>
                <div style={{ width: '100%' }}>
                    <br />
                    <div
                        className="menu-up-hover"
                        style={{
                            background: 'linear-gradient(0deg, #ff0844 0%, #ffb199 100%)',
                            padding: '10px',
                            color: 'white',
                            borderRadius: '10px',
                            margin: '0 auto',
                            boxShadow: '0px 2px 8px #000000',
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}>
                        フォローUPパス
                    </div>
                </div>
            </>
        ),
        icon: '',
        type: 'item',
        to: '/follow-up',
        checked: true,
        noHover: true,
    },
];
