import React from 'react';
import './style.scss';
import { Button } from '..';

type TypeProps = {
    title?: string;
    subTitle?: string;
    content?: React.ReactNode;
    event?: (arg?: any) => any;
    buttonTitle?: string;
    buttonEvent?: (arg?: any) => any;
    prevTitle?: string;
    buttonEventPrev?: (arg?: any) => any;
    disabledButton?: boolean;
    theme?: boolean
};

const Box = ({
    title = '',
    content = '',
    event = () => {},
    buttonTitle = '',
    buttonEvent = () => {},
    disabledButton = false,
    subTitle = '',
    theme = false,
    prevTitle = '',
    buttonEventPrev = () => {},
}: TypeProps) => {
    const ref = React.useRef<any>();
    return (
        <form ref={ref} onSubmit={event}>
            <div className="box">
                <div className="box-title">
                    {title}
                    {subTitle && (
                        <React.Fragment>
                            <br />
                            <span>{subTitle}</span>
                        </React.Fragment>
                    )}
                </div>
                <div className="box-content">{content}</div>
                <div className="box-event">
                    {
                        prevTitle && <Button
                        onClick={() => {
                            let obj = {};
                            const elements = ref.current.elements;
                            for (var i = 0; i < elements.length; i++) {
                                const item = elements.item(i);
                                if (item.name) {
                                    obj[item.name] = item.value;
                                }
                            }
                            buttonEventPrev(obj);
                        }}
                        theme={theme ? 'primary' : 'info'}
                        title={prevTitle}
                        disabled={disabledButton}
                    />
                    }
                    <Button
                        onClick={() => {
                            let obj = {};
                            const elements = ref.current.elements;
                            for (var i = 0; i < elements.length; i++) {
                                const item = elements.item(i);
                                if (item.name) {
                                    obj[item.name] = item.value;
                                }
                            }
                            buttonEvent(obj);
                        }}
                        theme={theme ? 'info' : 'primary'}
                        title={buttonTitle}
                        disabled={disabledButton}
                    />
                </div>
            </div>
        </form>
    );
};

export default Box;
