import React from 'react';
import { FaAddressCard } from 'react-icons/fa';
import { Button } from '../../component';
import './ListProfile.scss';
import { useNavigate } from 'react-router-dom';
import { onOpenSnS } from '../../constants/sns';
import { logoNetwork } from '../../constants/profile';

const ProfileItem = ({ profile }) => {
    
    const navigate = useNavigate();
    return (
        <div className="profile-item">
            <div className="profile-item-line"></div>
            <div className="profile-item-content">
                <img className="profile-item-content-avatar" alt="画像" src={profile.avatar} />
                <div className="profile-item-content-name">{profile.nickname}</div>
                <div className="profile-item-content-network">
                    {profile.networks.length
                        ? profile.networks.map((network, index) => (
                              <img
                                  onClick={() => onOpenSnS(network, profile, false)}
                                  style={{ cursor: 'pointer' }}
                                  key={index}
                                  alt="画像"
                                  src={logoNetwork[network.type]}
                              />
                          ))
                        : ''}
                </div>
                <Button
                    onClick={() => navigate(`/${profile.id}`)}
                    icon={<FaAddressCard />}
                    title="プロフィール詳細"
                    style={{ width: '100%' }}
                />
            </div>
        </div>
    );
};

export default ProfileItem;
