// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.follow-up-page {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
}
.follow-up-page img {
  width: 33%;
  cursor: pointer;
  transform: scale(1.15);
}

.follow-up-page-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.follow-up-page-img:hover {
  transform: scale(1.2);
  transition: all 0.5s;
}

.follow-up-page-img:active {
  transform: scale(0.9);
  transition: all 0.2s;
}

@media only screen and (min-width: 550px) and (max-width: 792px) {
  .follow-up-page {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    gap: 10px;
  }
  .follow-up-page img {
    height: 220px;
    object-fit: cover;
    width: 400px;
  }
  .follow-up-page-title {
    font-size: 21px;
  }
}
@media only screen and (max-width: 550px) {
  .follow-up-page {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }
  .follow-up-page img {
    height: 200px;
    object-fit: cover;
    width: 100%;
    max-width: 350px;
  }
  .follow-up-page-title {
    font-size: 18px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/follow-up/FollowUpPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,QAAA;AACJ;AACI;EACI,UAAA;EACA,eAAA;EACA,sBAAA;AACR;;AAGA;EACI,eAAA;EACA,gBAAA;EAEA,mBAAA;AADJ;;AAIA;EACI,qBAAA;EACA,oBAAA;AADJ;;AAIA;EACI,qBAAA;EACA,oBAAA;AADJ;;AAKA;EACI;IACI,aAAA;IACA,mBAAA;IACA,6BAAA;IACA,sBAAA;IACA,SAAA;EAFN;EAIM;IACI,aAAA;IACA,iBAAA;IACA,YAAA;EAFV;EAME;IACI,eAAA;EAJN;AACF;AAQA;EACI;IACI,aAAA;IACA,mBAAA;IACA,6BAAA;IACA,sBAAA;EANN;EAQM;IACI,aAAA;IACA,iBAAA;IACA,WAAA;IACA,gBAAA;EANV;EAUE;IACI,eAAA;EARN;AACF","sourcesContent":[".follow-up-page {\n    display: flex;\n    align-items: center;\n    justify-content: space-around;\n    gap: 5px;\n\n    img {\n        width: 33%;\n        cursor: pointer;\n        transform: scale(1.15);\n    }\n}\n\n.follow-up-page-title {\n    font-size: 24px;\n    font-weight: 600;\n    // text-align: center;\n    margin-bottom: 20px;\n}\n\n.follow-up-page-img:hover {\n    transform: scale(1.2);\n    transition: all 0.5s;\n}\n\n.follow-up-page-img:active {\n    transform: scale(0.9);\n    transition: all 0.2s;\n}\n\n\n@media only screen and (min-width: 550px) and (max-width: 792px) {\n    .follow-up-page {\n        display: flex;\n        align-items: center;\n        justify-content: space-around;\n        flex-direction: column;\n        gap: 10px;\n\n        img {\n            height: 220px;\n            object-fit: cover;\n            width: 400px;\n        }\n    }\n\n    .follow-up-page-title {\n        font-size: 21px;\n    }\n\n}\n\n@media only screen and (max-width: 550px) {\n    .follow-up-page {\n        display: flex;\n        align-items: center;\n        justify-content: space-around;\n        flex-direction: column;\n\n        img {\n            height: 200px;\n            object-fit: cover;\n            width: 100%;\n            max-width: 350px;\n        }\n    }\n\n    .follow-up-page-title {\n        font-size: 18px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
