import React, { useState } from 'react';
import './LoginPage.scss';
import { Button, Checkbox, Input } from '../../component';
import { FaEnvelope, FaLock, FaUserPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { loginAccount } from '../../services/graphql/account';
import { REGEX_EMAIL, REGEX_PASSWORD } from '../../constants/regex';
import reState from '../../hooks/ReState';

const LoginPage = () => {
    const navigate = useNavigate();
    const { setAccount } = React.useContext(reState);
    const [dataLogin, setDataLogin] = useState({
        account: '',
        password: '',
        remember: true,
    });
    const [errorLogin, setErrorLogin] = useState({
        account: '',
        password: '',
        error: '',
    });
    const [loading, setLoading] = useState(false);

    const onChange = e => {
        const { name, value } = e.target;
        setDataLogin({
            ...dataLogin,
            [name]: value,
        });
        setErrorLogin({
            account: '',
            password: '',
            error: '',
        });
    };
    const loginEvent = async e => {
        const { account, password } = dataLogin;
        if (!account || account.trim().length < 1)
            setErrorLogin({
                ...errorLogin,
                account: 'アカウントを空にすることはできません',
            });
        else if (account.length < 6 || !REGEX_EMAIL.test(account.trim()))
            setErrorLogin({
                ...errorLogin,
                account: 'アカウントの形式が正しくありません',
            });
        else if (!password || password.trim().length < 1)
            setErrorLogin({
                ...errorLogin,
                password: 'パスワードを空白にすることはできません',
            });
        else if (!REGEX_PASSWORD.test(password) || password.length < 6) {
            setErrorLogin({
                ...errorLogin,
                password: 'パスワードの形式が正しくありません',
            });
        } else {
            setLoading(true);
            const result = await loginAccount({ account, password });

            if (result.status === '200') {
                const profile = result.resul[0];
                if (profile.status === 'normal') {
                    localStorage.setItem('__ip', result.resul[0].id);
                    sessionStorage.setItem('__at', result.token);
                    if (dataLogin.remember) {
                        localStorage.setItem('__ft', result.refreshToken);
                    }
                    setAccount({
                        at: result.token,
                        ft: result.refreshToken,
                        id: result.resul[0].id,
                    });
                    navigate('/');
                } else {
                    localStorage.setItem('__ip', result.resul[0].id);
                    sessionStorage.setItem('__at', result.token);
                    if (dataLogin.remember) {
                        localStorage.setItem('__ft', result.refreshToken);
                    }
                    setAccount({
                        at: result.token,
                        ft: result.refreshToken,
                        id: result.resul[0].id,
                    });
                    navigate('/');
                }
                setLoading(false);
            } else {
                setErrorLogin({
                    ...errorLogin,
                    error: result.mess,
                });
                setLoading(false);
            }
        }
    };
    React.useEffect(() => {
        document.body.style.overflowY = 'auto';
        document.body.style.paddingBottom = '10px';
        // eslint-disable-next-line
    }, []);
    return (
        <React.Fragment>
            <div className="login-page-title">マイページログイン</div>

            {errorLogin.error && (
                <React.Fragment>
                    <br />
                    <div className="login-page-error">{errorLogin.error}</div>
                </React.Fragment>
            )}
            <br />
            <Input
                errorText={errorLogin.account}
                error={errorLogin.account}
                name="account"
                value={dataLogin.account}
                onChange={onChange}
                icon={<FaEnvelope />}
                placeholder="ご登録のメールアドレス"
            />
            <br />
            <Input
                errorText={errorLogin.password}
                error={errorLogin.password}
                name="password"
                value={dataLogin.password}
                onChange={onChange}
                icon={<FaLock />}
                placeholder="ご登録のパスワード"
                type="password"
            />
            <br />
            <div className="login-page-100">
                <div className="login-page-70">
                    <Checkbox
                        defaultValue={1}
                        options={[
                            {
                                label: 'ログイン状態を保持',
                                value: 1,
                                checked: dataLogin.remember,
                            },
                        ]}
                        onChange={value => {
                            setDataLogin({
                                ...dataLogin,
                                remember: !value.checked,
                            });
                        }}
                    />
                </div>
                <div className="login-page-30">
                    <Button disabled={loading} onClick={loginEvent} title="ログイン" />
                </div>
            </div>
            <br />
            <div className="login-page-title">- または -</div>
            <br />
            <Button
                onClick={() => navigate('/register')}
                icon={<FaUserPlus />}
                title="新規登録"
                style={{ width: '100%' }}
            />
            <br />
            <div className="login-page-note" onClick={() => navigate('/forgot-password')}>
                パスワードをお忘れの方はこちら
            </div>
        </React.Fragment>
    );
};

export default LoginPage;
