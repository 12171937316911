import {  FaExclamationTriangle } from 'react-icons/fa';
export const notificationsType = data => {
    if (data) {
        if (data.type === typeNotification.WARNING) {
            return {
                type: 'white',
                line: 'left',
                theme: 'warning',
                title: (
                    <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                        <FaExclamationTriangle style={{ color: 'rgb(251 192 9)' }} />
                        {data.title}
                    </div>
                ),
                content: <div dangerouslySetInnerHTML={{__html: data.content}}></div>,
            };
        } else if (data.type === typeNotification.NOTIFICATION) {
            return {
                type: 'white',
                line: 'top',
                theme: 'info',
                title: data.title,
                content: data.content,
            };
        } else if (data.type === typeNotification.SUCCESS) {
            return {
                type: 'green',
                line: 'default',
                theme: '',
                title: data.title,
                content: data.content,
            };
        }
    }
    return null;
};

export const typeNotification = {
    WARNING: 2,
    NOTIFICATION: 1,
    SUCCESS: 1,
};
