const onHandleAddressSearchProfile = (e, setDataSearch, dataSearch) => {
    setDataSearch({
        ...dataSearch,
        address: e ? e : null,
    });
};

const onHandleSexSearchProfile = (e, setDataSearch, dataSearch) => {
    setDataSearch({
        ...dataSearch,
        sex: e ? e - 1 : null,
    });
};
const onHandleYearSearchProfile = (e, setDataSearch, dataSearch) => {
    setDataSearch({
        ...dataSearch,
        yearEnd: e.yearEnd ? new Date().getFullYear() - e.yearEnd : null,
        yearStart: e.yearStart ? new Date().getFullYear() - e.yearStart : null,
    });
};
const onHandleJobSearchProfile = (e, setDataSearch, dataSearch) => {
    setDataSearch({
        ...dataSearch,
        job: e ? e : null,
    });
};
const onHandleInterestSearchProfile = (e, setDataSearch, dataSearch) => {
    setDataSearch({
        ...dataSearch,
        interests: e,
    });
};

export {
    onHandleAddressSearchProfile,
    onHandleJobSearchProfile,
    onHandleSexSearchProfile,
    onHandleYearSearchProfile,
    onHandleInterestSearchProfile,
};
