import React from 'react';
import { Notification, PageTitle, Skeleton } from '../../component';
import ProfileItem from '../search-profile/ProfileItem';
import './TodayNewsPage.scss';
import { profileAPI } from '../../services/graphql/profile';
import { SmileOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import { convertSnsProfile } from '../../helper/profile';
import reState from '../../hooks/ReState';

const TodayNewsPage = () => {
    const { listTodayNews, setlistTodayNews } = React.useContext(reState);
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        if (!listTodayNews) {
            const callAPI = async () => {
                setLoading(true);
                const tokenToday = localStorage.getItem('listTodayNews') || '';
                const result = await profileAPI({ size: 1, random: true, tokenToday });
                if (result && result.status === '200') {
                    setlistTodayNews(result.resul);
                    localStorage.setItem('listTodayNews', result.token);
                }
                setLoading(false);
            };
            callAPI();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <React.Fragment>
            <PageTitle title="ピックアップ" />
            <br />
            <Notification
                // title="フォローUPとは？"
                content={
                    <React.Fragment>
                        あなたのことが好きなユーザーをAIが発見!
                        <br />
                        本日の要注目ユーザーはこの方です!!
                    </React.Fragment>
                }
            />
            <br />
            <div className="today-news-page">
                {listTodayNews && listTodayNews.length && !loading
                    ? listTodayNews?.map((profile, key) => (
                          <ProfileItem key={key} {...{ profile: convertSnsProfile(profile) }} />
                      ))
                    : ''}
                {loading ? (
                    Array(6)
                        .fill('')
                        .map(i => <Skeleton key={i} className="profile-item" style={{ height: '150px' }} />)
                ) : listTodayNews && listTodayNews.length ? (
                    ''
                ) : (
                    <Result icon={<SmileOutlined />} title="結果が見つかりません" />
                )}
            </div>
        </React.Fragment>
    );
};

export default TodayNewsPage;
