import React from 'react';
import './style.scss';

type TypeProps = {
    line?: 'left' | 'top' | 'default';
    theme?: 'primary' | 'warning' | 'info' | 'error';
    title?: React.ReactNode;
    titleString?: string;
    content?: React.ReactNode;
    type?: 'white' | 'green' | 'red';
    hidden?: 'mobile' | 'pc' | 'default';
};

const index = ({
    line = 'left',
    theme = 'primary',
    title,
    content,
    type = 'white',
    hidden = 'default',
    titleString = '',
}: TypeProps) => {
    return (
        <div className={`notification notification-${line} notification-type-${type} notification-hidden-${hidden}`}>
            <div className={`notification-line-${line} notification-line-${theme}`}></div>
            <div className="notification-box">
                {title && (
                    <div className="notification-box-title" >
                        {title}
                    </div>
                )}
                {titleString && (
                    <div className="notification-box-title" dangerouslySetInnerHTML={{ __html: titleString }}>
                    </div>
                )}
                {content && <div className="notification-box-content">{content}</div>}
            </div>
        </div>
    );
};

export default index;
