import React from 'react';
import { Link } from 'react-router-dom';
import './CancelSuccessPage.scss';
import Logo from '../../assets/logo.png';
import reState from '../../hooks/ReState';

const CancelSuccessPage = () => {
    const { profile } = React.useContext(reState);

    React.useEffect(() => {
        document.body.style.overflowY = 'auto';
        document.body.style.paddingBottom = '70px';
        // eslint-disable-next-line
    }, []);
    return (
        <React.Fragment>
            <>
                <br />
                <br />
                <div className="cancel-account-success-page-logo">
                    <img alt="画像" src={Logo} />
                </div>
                <div className="cancel-account-success-page">
                    <div>
                        ご解約手続きが完了しました。
                        <br />
                        insta-styleをご利用いただき誠にありがとうございました。
                    </div>
                    <br />
                    <Link
                        style={{
                            color: 'rgb(255 150 206)',
                            textAlign: 'center',
                            textDecoration: 'none',
                            fontWeight: '600',
                            fontSize: '18px',
                        }}
                        to={profile ? '/' : '/login'}>
                        insta-style
                    </Link>
                </div>
            </>
        </React.Fragment>
    );
};

export default CancelSuccessPage;
