import React from 'react';
import './ListProfile.scss';
import ProfileItem from './ProfileItem';
import { convertSnsProfile } from '../../helper/profile';

const ListProfile = ({listSearchProfile}) => {
    return (
        <div className="list-profile">
            {listSearchProfile &&
                listSearchProfile.map((searchItem, i) => {
                    return <ProfileItem key={i} {...{ profile: convertSnsProfile(searchItem) }} />;
                })}
        </div>
    );
};

export default ListProfile;
