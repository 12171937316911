import React, { Fragment, useState } from 'react';
import './style.scss';
import { FormNoteText, FormSubText, FormTick, FormTitle } from '..';
import { Cascader } from 'antd';
import { IoMdArrowDropdown } from 'react-icons/io';

type TypeProps = {
    title?: string;
    subText?: string;
    tick?: string;
    noteText?: string;
    onChange?: (arg?: any) => any;
    disabled?: boolean;
    placeholder?: string;
};

const CassagerYear = ({
    title = '',
    tick = '',
    subText = '',
    noteText = '',
    onChange = () => {},
    placeholder = '',
}: TypeProps) => {
    const [options, setOptions] = useState([
        {
            label: '選択〜選択',
            value: 0,
            yearEnd: null,
            yearStart: null,
        },
        ...Array(48)
            .fill('')
            .map((value, i) => {
                return {
                    value: i + 18,
                    label: i + 18 + '歳',
                    isLeaf: false,
                };
            }),
    ]);
    const loadData = (selectedOptions: any) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        if (targetOption.value) {
            targetOption.loading = true;

            setTimeout(() => {
                targetOption.loading = false;
                targetOption.children =
                    65 - targetOption.value === 0
                        ? [
                              {
                                  value: 0,
                                  label: '65歳以上',
                              },
                          ]
                        : [
                              ...Array(65 - targetOption.value)
                                  .fill('')
                                  .map((value, i) => {
                                      return {
                                          value: i + targetOption.value + 1,
                                          label: i + targetOption.value + 1 + '歳',
                                      };
                                  }),
                              ...[
                                  {
                                      value: 0,
                                      label: '65歳以上',
                                  },
                              ],
                          ];
                setOptions([...options]);
            }, 500);
        }
    };
    return (
        <Fragment>
            {title && (
                <div style={{ marginBottom: '10px' }}>
                    <FormTitle>{title}</FormTitle>
                    {subText && <FormSubText>{subText}</FormSubText>}
                    {tick && <FormTick>{tick}</FormTick>}
                </div>
            )}
            <div className="select">
                <Cascader
                    loadData={loadData}
                    expandIcon={<></>}
                    displayRender={label => label.join('~')}
                    options={options}
                    placeholder={placeholder}
                    allowClear={false}
                    suffixIcon={<IoMdArrowDropdown />}
                    style={{
                        width: '100%',
                    }}
                    onChange={e => {
                        onChange({
                            yearEnd: e[0] ? e[0] : null,
                            yearStart: e[0] ? e[1] : null,
                        });
                    }}
                />
            </div>
            {noteText && (
                <div>
                    <FormNoteText>{noteText}</FormNoteText>
                </div>
            )}
        </Fragment>
    );
};

export default CassagerYear;
