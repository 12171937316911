import React from 'react';
import { Form, Input, Notification, PageTitle } from '../../../component';
import { defaultErrorSNSSetting, typeTabSetting } from '../../../constants/setting';
import { updateProfileAPI } from '../../../services/graphql/profile';
import { useNavigate } from 'react-router-dom';
import reState from '../../../hooks/ReState';
import { notification } from 'antd';
import LogoInstagram from '../../../assets/instagram.png';
import LogoTikTok from '../../../assets/tik-tok.png';
import LogoTwitter from '../../../assets/twitter.png';
import LogoYoutube from '../../../assets/youtube.png';

const SettingThree = ({ setDataUpdate, dataUpdate, setTabSetting }) => {
    const [errorUpdate, setErrorUpdate] = React.useState(defaultErrorSNSSetting);
    const [messageApi, contextHolder] = notification.useNotification();
    const onClearErrorUpdate = () => {
        setErrorUpdate(defaultErrorSNSSetting);
    };
    const navigate = useNavigate();
    const { profile, setProfile, stripeErrorPayment } = React.useContext(reState);
    return (
        <React.Fragment>
            {contextHolder}
            <PageTitle
                title="プロフィール設定 3/3"
                // breadcrumb={[
                //     {
                //         title: 'ボーム',
                //     },
                //     {
                //         title: 'マイプロフィール設定_3/3',
                //     },
                // ]}
            />
            {stripeErrorPayment && (
                <>
                    <br />
                    <Notification
                        title="決済エラーの場合"
                        content={
                            <React.Fragment>
                                決済エラーの場合、ご利用可能なお支払い情報へ更新されますと自動的に決済が再開されます。
                            </React.Fragment>
                        }
                        line="default"
                        type="red"
                    />
                </>
            )}
            {errorUpdate.instagram && errorUpdate.twitter && errorUpdate.tiktok && errorUpdate.youtube ? (
                <>
                    <br />
                    <Notification line="default" content="SNSアカウントはどれが一つ以上入力してください。" type="red" />
                </>
            ) : (
                <></>
            )}
            <br />
            <Form
                buttonEvent={async e => {
                    const newDataUpdate = {
                        ...dataUpdate,
                        ...e,
                    };
                    setDataUpdate({ ...newDataUpdate });

                    const {
                        id: updateProfileId,
                        username,
                        fullName,
                        email,
                        packDataStt,
                        nickname,
                        sex,
                        birthday,
                        introduceYourself,
                        instagram,
                        tiktok,
                        youtube,
                        addressStt,
                        avatar,
                        jobStt,
                        interests,
                        twitter,
                    } = newDataUpdate;

                    if (!instagram && !twitter && !tiktok && !youtube) {
                        setErrorUpdate({
                            ...errorUpdate,
                            instagram: true,
                            tiktok: true,
                            twitter: true,
                            youtube: true,
                        });
                        messageApi.error({
                            message: 'SNSアカウントは1つ以上入力してください',
                            placement: 'bottomRight',
                        });
                    } else if (instagram && instagram.trim().length < 5) {
                        setErrorUpdate({
                            ...errorUpdate,
                            instagram: 'Instagram に正しい形式で入力してください',
                        });
                        messageApi.error({
                            message: 'Instagram に正しい形式で入力してください',
                            placement: 'bottomRight',
                        });
                    } else if (twitter && twitter.trim().length < 5) {
                        setErrorUpdate({
                            ...errorUpdate,
                            twitter: 'X に正しい形式で入力してください',
                        });
                        messageApi.error({
                            message: 'X に正しい形式で入力してください',
                            placement: 'bottomRight',
                        });
                    } else if (tiktok && tiktok.trim().length < 5) {
                        setErrorUpdate({
                            ...errorUpdate,
                            tiktok: 'Tiktok を正しい形式で入力してください',
                        });
                        messageApi.error({
                            message: 'Tiktok を正しい形式で入力してください',
                            placement: 'bottomRight',
                        });
                    } else if (youtube && youtube.trim().length < 5) {
                        setErrorUpdate({
                            ...errorUpdate,
                            youtube: 'Youtube に正しい形式で入力してください',
                        });
                        messageApi.error({
                            message: 'Youtube に正しい形式で入力してください',
                            placement: 'bottomRight',
                        });
                    } else {
                        const result = await updateProfileAPI({
                            updateProfileId,
                            username,
                            fullName,
                            email,
                            packDataStt,
                            nickname,
                            sex,
                            birthday,
                            introduceYourself,
                            instagram,
                            tiktok,
                            youtube,
                            addressStt,
                            avatar,
                            jobStt,
                            interests,
                            twitter,
                        });

                        if (result && result.status === '200') {
                            messageApi.success({
                                message: 'アカウント情報を正常に更新します。',
                                placement: 'bottomRight',
                            });
                            if (result.resul[0]) {
                                setProfile({
                                    ...newDataUpdate,
                                    ...result.resul[0],
                                });
                            } else {
                                setProfile(newDataUpdate);
                            }

                            navigate(`/${profile ? profile.id : ''}`);
                        } else {
                            messageApi.error({
                                message: result && result.mess,
                                placement: 'bottomRight',
                            });
                        }
                    }
                }}
                subTitle="※最低1つは登録が必要です"
                content={
                    <React.Fragment>
                        <br />
                        <Input
                            title={
                                <span style={{ display: 'inline-flex', alignItems: 'center', gap: 5 }}>
                                    <img style={{ width: '16px' }} alt="画像" src={LogoInstagram} />
                                    Instagram
                                </span>
                            }
                            high
                            noteText="Instagramユーザーネーム"
                            placeholder="ユーザーネーム"
                            defaultValue={dataUpdate.instagram}
                            name="instagram"
                            error={errorUpdate.instagram}
                            errorText={errorUpdate.instagram}
                            onChange={onClearErrorUpdate}
                        />
                        <br />
                        <Input
                            title={
                                <span style={{ display: 'inline-flex', alignItems: 'center', gap: 5 }}>
                                    <img style={{ width: '16px' }} alt="画像" src={LogoTwitter} />
                                    X
                                </span>
                            }
                            high
                            noteText="Xアカウント"
                            placeholder="アカウント"
                            defaultValue={dataUpdate.twitter}
                            name="twitter"
                            error={errorUpdate.twitter}
                            errorText={errorUpdate.twitter}
                            onChange={onClearErrorUpdate}
                        />
                        <br />
                        <Input
                            title={
                                <span style={{ display: 'inline-flex', alignItems: 'center', gap: 5 }}>
                                    <img style={{ width: '16px' }} alt="画像" src={LogoTikTok} />
                                    TikTok
                                </span>
                            }
                            high
                            noteText="TikTokアカウント"
                            placeholder="アカウント"
                            defaultValue={dataUpdate.tiktok}
                            name="tiktok"
                            error={errorUpdate.tiktok}
                            errorText={errorUpdate.tiktok}
                            onChange={onClearErrorUpdate}
                        />
                        <br />
                        <Input
                            title={
                                <div style={{ display: 'inline-flex', alignItems: 'center', gap: 5 }}>
                                    <img style={{ width: '16px' }} alt="画像" src={LogoYoutube} />
                                    YouTube
                                </div>
                            }
                            noteText="YouTubeチャンネルID"
                            placeholder="チャンネルID"
                            defaultValue={dataUpdate.youtube}
                            name="youtube"
                            error={errorUpdate.youtube}
                            errorText={errorUpdate.youtube}
                            onChange={onClearErrorUpdate}
                        />
                    </React.Fragment>
                }
                title="SNSを登録してください"
                buttonTitle="完了"
                prevTitle='戻る'
                buttonEventPrev={() => setTabSetting(typeTabSetting.TWO)}
            />
        </React.Fragment>
    );
};

export default SettingThree;
