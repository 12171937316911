import { Result } from 'antd';
import React from 'react';
import { Button } from '../../component';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigation = useNavigate();
    return (
        <Result
            status="404"
            title=""
            subTitle={
                <>
                    只今サーバーが混み合っております。
                    <br />
                    ご迷惑をおかけして申し訳ありませんが、
                    <br />
                    しばらく時間をおいてから再度アクセスしてください。
                </>
            }
            extra={
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button onClick={() => navigation('/')} title="ホームに戻る" />
                </div>
            }
        />
    );
};

export default NotFound;
