import React from 'react';
import './style.scss';

type TypeProps = {
    title?: string;
    breadcrumb?: {
        title?: string;
        to?: string;
    }[];
};
const index = ({ title = '', breadcrumb = [] }: TypeProps) => {
    return (
        <div className="page-title">
            <div className="page-title-size">{title}</div>
            <div>
                {breadcrumb && breadcrumb.length
                    ? breadcrumb?.map((value, i) => {
                          if (i === breadcrumb.length - 1) {
                              return <span key={i}>/ {value.title} </span>;
                          }
                          if (i < breadcrumb.length - 1 && i > 0) {
                              return <span key={i} style={{ color: 'hwb(343deg 59% 3%)' }}>/ {value.title} </span>;
                          }
                          return <span key={i} style={{ color: 'hwb(343deg 59% 3%)' }}>{value.title} </span>;
                      })
                    : ''}
            </div>
        </div>
    );
};

export default index;
