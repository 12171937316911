export const typeTabSetting = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
};

export const defaultErrorSetting = {
    fullName: null,
    nickname: null,
    birthday: null,
    address: null,
    job: null,
    interest: null,
};

export const defaultErrorSNSSetting = {
    instagram: null,
    twitter: null,
    tiktok: null,
    youtube: null,
};
