import client from '../../apollo-client';
import { gql } from '@apollo/client';
 

type TypeProps = {
    keySearch?: String
};

export const listJobAPI = async ({ keySearch = "" }: TypeProps) => {
    const data: any = await client.mutate({
        mutation: gql`
            mutation ListJob($keySearch: String) {
                listJob(keySearch: $keySearch) {
                    resul {
                        stt
                        job
                        note
                    }
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            keySearch,
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.listJob ?? {};
};
