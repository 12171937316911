import React from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { Button, Form, Notification, PageTitle, Select } from '../../../component';
import { listPackage } from '../../../services/listPack/listPack';
import reState from '../../../hooks/ReState';
import { Modal, Result, notification } from 'antd';
import { changePlanAPI } from '../../../services/graphql/plan';

const ChangePlan = () => {
    const {
        stripePriceSubscription,
        stripeNameSubscription,
        account,
        setStripeNameSubscription,
        setStripePriceSubscription,
    } = React.useContext(reState);
    const [messageApi, contextHolder] = notification.useNotification();
    const [listPack, setListPack] = React.useState([]);
    const [newSubcritions, setNewSubcritions] = React.useState();
    const [openModal, setOpenModal] = React.useState(false);
    const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState();

    React.useEffect(() => {
        const listData = async () => {
            if (listPack.length === 0) {
                const data = await listPackage();
                if (data && data.length > 0 && !listPack.length) {
                    setListPack(data.reverse().filter(value => value.id !== process.env.REACT_APP_FREE_PLAN));
                }
            }
        };
        listData();
        // eslint-disable-next-line
    }, []);

    const onSubmit = () => {
        if (stripePriceSubscription === newSubcritions) {
            messageApi.error({
                message: '現在のプランとは別のプランを選択してください。',
                placement: 'topRight',
            });
        } else {
            setOpenModal(true);
        }
    };
    const onChangePlan = async () => {
        setLoading(true);
        const result = await changePlanAPI({
            id: Number(account?.id),
            plan: newSubcritions,
        });
        if (result && result.status === '200') {
            setStripeNameSubscription(listPack.find(value => value.default_price === newSubcritions)?.name);
            setStripePriceSubscription(newSubcritions);
            setOpenSuccessModal(true);
            setTimeout(() => {
                closeModel();
            }, 5000);
        } else {
            setOpenModal(false);
            setError(result.mess || '');
        }
        setLoading(false);
    };
    const closeModel = () => {
        setOpenModal(false);
        setOpenSuccessModal(false);
    };
    return (
        <React.Fragment>
            {contextHolder}
            <PageTitle title="料金プラン変更" />
            <Modal
                onOk={() => {}}
                onCancel={() => (loading ? {} : closeModel())}
                centered
                open={openModal}
                closable={false}
                footer={null}>
                {openSuccessModal ? (
                    <Result
                        status="success"
                        title="料金プランの変更は完了しました。"
                        extra={[
                            <Button
                                disabled={loading}
                                onClick={() => closeModel()}
                                style={{ width: '100%' }}
                                title="OK"
                                theme="info"
                            />,
                        ]}
                    />
                ) : (
                    <>
                        <h1 className="modal-text">料金プラン変更</h1>
                        <h4>プラン変更時に決済が発生いたします。</h4>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                            <Button
                                disabled={loading}
                                onClick={() => closeModel()}
                                style={{ width: '45%' }}
                                title="キャンセル"
                                theme="info"
                            />
                            <Button
                                disabled={loading}
                                loading={loading}
                                onClick={onChangePlan}
                                style={{ width: '45%' }}
                                title="変更する"
                                theme="primary"
                            />
                        </div>
                    </>
                )}
            </Modal>
            <br />
            <Notification
                title={
                    <>
                        <FaRegQuestionCircle />
                        プラン変更について
                    </>
                }
                content={
                    <React.Fragment>
                        プラン変更前の決済日に関わらず、プラン変更時に再度決済が発生いたします。
                    </React.Fragment>
                }
            />
            <br />

            <Form
                buttonEvent={onSubmit}
                disabledButton={!newSubcritions}
                content={
                    <React.Fragment>
                        {error && (
                            <Notification
                                title="エラーが発生しました"
                                content={<React.Fragment>{error}</React.Fragment>}
                                line="default"
                                type="red"
                            />
                        )}

                        <br />

                        <div>
                            <span className="ui-title">現在のプラン</span>: <span>{stripeNameSubscription}</span>
                            <br />
                        </div>
                        <br />

                        <Select
                            onChange={e => {
                                setNewSubcritions(e);
                                setError();
                            }}
                            placeholder="入力してください"
                            value={
                                newSubcritions ??
                                (listPack.map(pack => pack?.default_price).includes(stripePriceSubscription)
                                    ? stripePriceSubscription
                                    : '')
                            }
                            options={listPack.map(pack => {
                                return {
                                    ...pack,
                                    label: `${pack?.name}`,
                                    value: pack?.default_price,
                                };
                            })}
                            showSearch
                            disabled={listPack.length > 0 ? false : true}
                        />
                    </React.Fragment>
                }
                title="ご利用のプランを変更することができます。"
                buttonTitle="変更"
            />
        </React.Fragment>
    );
};

export default ChangePlan;
