// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-page-50 {
  width: calc(50% - 10px);
}

.sign-page-100 {
  display: flex;
  gap: 20px;
}

.sign-page-checkbox {
  color: rgb(255, 150, 206);
}

.sign-page-link {
  color: rgb(255, 150, 206);
}

.modal-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-text {
  color: rgb(70, 70, 70);
  text-align: center;
}

.sign-page-title {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-page-error {
  background-color: rgb(255, 62, 62);
  color: white;
  font-weight: 500;
  padding: 10px;
  border-radius: 4px;
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/pages/register/SignPage.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;;AACA;EACI,aAAA;EACA,SAAA;AAEJ;;AACA;EACI,yBAAA;AAEJ;;AAAA;EACI,yBAAA;AAGJ;;AADA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AAIJ;;AAFA;EACI,sBAAA;EACA,kBAAA;AAKJ;;AAHA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAMJ;;AAJA;EACI,kCAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;AAOJ","sourcesContent":[".sign-page-50 {\n    width: calc(50% - 10px);\n}\n.sign-page-100 {\n    display: flex;\n    gap: 20px;\n}\n\n.sign-page-checkbox {\n    color: rgb(255 150 206);\n}\n.sign-page-link{\n    color: rgb(255 150 206);\n}\n.modal-img{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.modal-text{\n    color: rgb(70, 70, 70);\n    text-align: center;\n}\n.sign-page-title{\n    height: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.sign-page-error{\n    background-color: rgb(255, 62, 62);\n    color: white;\n    font-weight: 500;\n    padding: 10px;\n    border-radius: 4px;\n    font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
