import axios from 'axios';

export const uploadFileAPI = async (file: any) => {
    const formData = new FormData();
    formData.append('avatar', file);
    const result = await axios.post(process.env.REACT_APP_GRAPHQL_URL + 'upload-image', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (result.data) {

        return result.data;
    }
    return {
        status: 'ERROR_500',
    };
};
