import client from '../../apollo-client';
import { gql } from '@apollo/client';
 

type TypeProps = {
    oldPassword: String;
    newPassword: String;
};

export const changePasswordAPI = async ({ oldPassword = '', newPassword = '' }: TypeProps) => {
    const changePasswordId = localStorage.getItem('__ip');
    const data: any = await client.mutate({
        mutation: gql`
            mutation ChangePassword($oldPassword: String!, $newPassword: String!, $changePasswordId: ID!) {
                changePassword(oldPassword: $oldPassword, newPassword: $newPassword, id: $changePasswordId) {
                    resul
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            oldPassword,
            newPassword,
            changePasswordId,
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.changePassword ?? {};
};
