export const convertSnsProfile = searchItem => {
    if(!searchItem){
        return {}
    }
    return {
        ...searchItem,
        networks: [
            ...(searchItem.instagram
                ? [
                      {
                          type: 'instagram',
                          url: process.env.REACT_APP_URL_INSTAGRAM + searchItem.instagram,
                      },
                  ]
                : []),
            ...(searchItem.tiktok
                ? [
                      {
                          type: 'tik-tok',
                          url: process.env.REACT_APP_URL_TIKTOK + searchItem.tiktok,
                      },
                  ]
                : []),
            ...(searchItem.twitter
                ? [
                      {
                          type: 'twitter',
                          url: process.env.REACT_APP_URL_TWITTER + searchItem.twitter,
                      },
                  ]
                : []),
            ...(searchItem.youtube
                ? [
                      {
                          type: 'youtube',
                          url: process.env.REACT_APP_URL_YOUTUBE + searchItem.youtube,
                      },
                  ]
                : []),
        ],
    };
};
