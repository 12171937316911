import React, { useState } from 'react';
import { Form, Input, Notification, PageTitle } from '../../../component';
import { typeTabSetting } from '../../../constants/setting';
import { Modal, Upload, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import reState from '../../../hooks/ReState';
import { uploadFileAPI } from '../../../services/axios/UploadImage';

const SettingTwo = ({ setTabSetting, dataUpdate, setDataUpdate }) => {
    // Avatar
    const { profile, stripeErrorPayment } = React.useContext(reState);
    const [messageApi, contextHolder] = notification.useNotification();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const handleCancel = () => setPreviewOpen(false);
    const handleUpload = async file => {
        const result = await uploadFileAPI(file);
        if (result.status === '200') {
            setFileList([
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: result.resul,
                },
            ]);
            setDataUpdate({
                ...dataUpdate,
                avatar: result.resul,
            });
        } else {
            messageApi.error({
                message: 'アバターのアップロードに失敗しました。',
                placement: 'bottomRight',
            });
        }

        return Upload.LIST_IGNORE;
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}>
                Upload
            </div>
        </div>
    );

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = URL.createObjectURL(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    React.useEffect(() => {
        if (profile) {
            setFileList([
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: profile.avatar,
                },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <React.Fragment>
            {contextHolder}
            <PageTitle
                title="プロフィール設定 2/3
                "
                // breadcrumb={[
                //     {
                //         title: 'ボーム',
                //     },
                //     {
                //         title: 'マイプロフィール設定_2/3',
                //     },
                // ]}
            />
            {stripeErrorPayment && (
                <>
                    <br />
                    <Notification
                        title="決済エラーの場合"
                        content={
                            <React.Fragment>
                                決済エラーの場合、ご利用可能なお支払い情報へ更新されますと自動的に決済が再開されます。
                            </React.Fragment>
                        }
                        line="default"
                        type="red"
                    />
                </>
            )}
            {/* <br />
            <Notification title="プロフィールアイコン登録" /> */}
            <br />
            <Form
                buttonEvent={e => {
                    setDataUpdate({
                        ...dataUpdate,
                        ...e,
                    });
                    setTabSetting(typeTabSetting.THREE);
                }}
                content={
                    <React.Fragment>
                        <br />
                        <ImgCrop rotationSlider>
                            <Upload
                                accept="image/*"
                                listType="picture-circle"
                                fileList={fileList}
                                beforeUpload={handleUpload}
                                onPreview={handlePreview}>
                                {fileList.length >= 8 ? null : uploadButton}
                            </Upload>
                        </ImgCrop>
                        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                        <br />
                        <br />
                        <Input
                            title="自己紹介"
                            type="area"
                            defaultValue={dataUpdate.introduce_yourself}
                            name="introduceYourself"
                            placeholder="自己紹介を入力してください&#10;入力された内容は運営会社により確認されます。不適切な内容と判断された場合、サービスの利用を制限する場合があります"
                        />
                    </React.Fragment>
                }
                title="アイコンをアップロードしてください"
                buttonTitle="次へ"
                prevTitle='戻る'
                buttonEventPrev={() => setTabSetting(typeTabSetting.ONE)}
            />
        </React.Fragment>
    );
};

export default SettingTwo;
