import { createSnsAPI } from "../services/graphql/sns";

export const onOpenSnS = async(network, profile, noBlank) => {
    if(!noBlank){
        window.open(network.url,'_blank')
    }
    await createSnsAPI({
        actionSnsId: profile.id,
        type: network.type
    })
    
    
};
