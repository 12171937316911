// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  min-height: 100px;
  background-color: white;
  box-shadow: 0px 2px 3px 1px rgba(128, 128, 128, 0.356);
}
.box .box-title {
  border-radius: 3px 3px 0px 0px;
  display: flex;
  justify-content: center;
  gap: 2px;
  padding: 15px;
  background-color: rgb(255, 92, 182);
  color: white;
  flex-direction: column;
}
.box .box-title span {
  font-size: 12px;
}
.box .box-content {
  padding: 10px 15px 30px 15px;
}
.box .box-event {
  height: 65px;
  border-radius: 0px 0px 3px 3px;
  background-color: rgb(247, 247, 247);
  padding: 0px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/component/Box/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,iBAAA;EACA,uBAAA;EACA,sDAAA;AACJ;AAAI;EACI,8BAAA;EACA,aAAA;EACA,uBAAA;EACA,QAAA;EACA,aAAA;EACA,mCAAA;EACA,YAAA;EACA,sBAAA;AAER;AADQ;EACI,eAAA;AAGZ;AAAI;EACI,4BAAA;AAER;AAAI;EACI,YAAA;EACA,8BAAA;EACA,oCAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAER","sourcesContent":[".box {\n    display: flex;\n    flex-direction: column;\n    border-radius: 3px;\n    min-height: 100px;\n    background-color: white;\n    box-shadow: 0px 2px 3px 1px rgba(128, 128, 128, 0.356);\n    .box-title {\n        border-radius: 3px 3px 0px 0px;\n        display: flex;\n        justify-content: center;\n        gap: 2px;\n        padding: 15px;\n        background-color: rgb(255, 92, 182);\n        color: white;\n        flex-direction: column;\n        span {\n            font-size: 12px;\n        }\n    }\n    .box-content {\n        padding: 10px 15px 30px 15px;\n    }\n    .box-event {\n        height: 65px;\n        border-radius: 0px 0px 3px 3px;\n        background-color: rgb(247, 247, 247);\n        padding: 0px 15px;\n        display: flex;\n        align-items: center;\n        gap: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
