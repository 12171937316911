
import React, { CSSProperties } from "react";
import "./style.scss";

type TypeSkeleton = {
  type?: "circle" | "box"; // 1 : ---- , 2 : ---- , 3 : ----
  width?: string; //size in element
  height?: string; //size
  borderRadius?: number | string; //
  style?: CSSProperties;
  className?: string;
};

const Skeleton = ({
  type = "box",
  width = "100%",
  height = "100%",
  borderRadius = 5,
  style,
  className = "",
}: TypeSkeleton) => {
  return (
    <div
      style={{
        width: width,
        height: height,
        borderRadius: borderRadius,
        ...style,
      }}
      className={`skeleton-wrapper ${className}`}
    ></div>
  );
};

export default Skeleton;
