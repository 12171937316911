import React from 'react';
import { Form, Input, PageTitle } from '../../../component';
import reState from '../../../hooks/ReState';
import { REGEX_EMAIL } from '../../../constants/regex';
import { acceptEmailAPI, changeEmailAPI } from '../../../services/graphql/change-email';
import { notification } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

const ChangeEmail = props => {
    const { profile, setProfile } = React.useContext(reState);
    const route = useLocation();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = notification.useNotification();
    const [loading, setLoading] = React.useState(false);
    const [errorText, setErrorText] = React.useState({
        newEmail: null,
        reNewEmail: null,
    });
    const [dataText, setDataText] = React.useState({
        newEmail: null,
        reNewEmail: null,
    });
    const [redirect, setRedirect] = React.useState(false);

    React.useEffect(() => {
        if (route.search) {
            const callAPI = async () => {
                const result = await acceptEmailAPI({
                    token: route.search.replace('?key=', ''),
                });
                if (result && result.status === '200') {
                    messageApi.success({
                        message: 'メールを正常に変更します。',
                        placement: 'bottomRight',
                    });
                    setProfile({
                        ...profile,
                        email: result.resul,
                    });
                    setRedirect(true);
                } else {
                    messageApi.error({
                        message: result && result.mess ? result.mess : 'サーバーエラー',
                        placement: 'bottomRight',
                    });
                    navigate('/change-profile');
                }
            };
            callAPI();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route]);
    React.useEffect(() => {
        if (redirect) {
            navigate('/change-profile');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirect]);
    const onClearError = e => {
        const { name, value } = e.target;
        setDataText({
            ...dataText,
            [name]: value,
        });
        setErrorText({
            newEmail: null,
            reNewEmail: null,
        });
    };
    const handleChangeEmail = async e => {
        const { oldEmail, newEmail, reNewEmail } = e;

        if (!newEmail || newEmail.trim().length < 1) {
            setErrorText({
                ...errorText,
                newEmail: '新しいメールアドレスを入力してください',
            });
        } else if (!REGEX_EMAIL.test(newEmail.trim())) {
            setErrorText({
                ...errorText,
                newEmail: '新しいメールの形式が正しくありません',
            });
        } else if (newEmail.trim().toLowerCase() === oldEmail) {
            setErrorText({
                ...errorText,
                newEmail: 'すでに使用しているこの新しいメール',
            });
        } else if (!reNewEmail || reNewEmail.trim().length < 1) {
            setErrorText({
                ...errorText,
                reNewEmail: '新しいメールアドレスを再入力してください',
            });
        } else if (!REGEX_EMAIL.test(reNewEmail) || reNewEmail !== newEmail.trim()) {
            setErrorText({
                ...errorText,
                reNewEmail: '再入力した新着メールと新着メールが違う',
            });
        } else {
            setLoading(true);
            const result = await changeEmailAPI({ oldEmail, newEmail });
            if (result && result.status === '200') {
                messageApi.success({
                    message:
                        'メールの変更をリクエストしました。メールボックスをチェックして、メールの変更を確認してください。',
                    placement: 'bottomRight',
                });
                setDataText({
                    newEmail: '',
                    reNewEmail: '',
                });
                setLoading(false);
            } else {
                messageApi.error({
                    message: result && result.mess ? result.mess : 'サーバーエラー',
                    placement: 'bottomRight',
                });
                setLoading(false);
            }
        }
    };
    return (
        <React.Fragment>
            {contextHolder}
            <PageTitle title="メールアドレス変更" />
            <br />
            <Form
                // buttonEvent={e => setTabSetting(typeTabSetting.FOUR)}
                // subTitle="登録 し た メー ル ア ド レス を 変更 され る 方 は 、 新 し い メ ー ル アド レス を 入力 し て くだ さい 。"
                content={
                    <React.Fragment>
                        <br />
                        <Input name="oldEmail" disabled title="現在のメールアドレス" value={profile && profile.email} />
                        <br />
                        <Input
                            error={errorText.newEmail}
                            errorText={errorText.newEmail}
                            name="newEmail"
                            title="新しいメールアドレス"
                            tick="必須"
                            onChange={onClearError}
                            value={dataText.newEmail}
                        />
                        <br />
                        <Input
                            error={errorText.reNewEmail}
                            errorText={errorText.reNewEmail}
                            name="reNewEmail"
                            title="新しいメールアドレス(確認)"
                            tick="必須"
                            onChange={onClearError}
                            value={dataText.reNewEmail}
                        />
                    </React.Fragment>
                }
                title="ご登録のメールアドレスを変更することができます。"
                buttonTitle="変更"
                buttonEvent={handleChangeEmail}
                disabledButton={loading}
            />
        </React.Fragment>
    );
};

export default ChangeEmail;
