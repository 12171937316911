import React from 'react';
import './style.scss';
import { FormNoteText, FormSubText, FormTick, FormTitle } from '..';
import { Radio, Space } from 'antd';

type TypeProps = {
    title?: string;
    subText?: string;
    tick?: string;
    noteText?: string;
    onChange?: (arg?: any) => any;
    value?: any;
    options?: {
        value?: any;
        title?: React.ReactNode;
        disabled?: boolean;
        [key: string]: any;
    }[];
    disabled?: boolean;
    errorText?: React.ReactNode;
};

const RadioForm = ({
    title = '',
    tick = '',
    subText = '',
    noteText = '',
    onChange = () => {},
    value,
    options = [],
    disabled = false,
    errorText = '',
}: TypeProps) => {
    return (
        <React.Fragment>
            {title && (
                <div style={{ marginBottom: '10px' }}>
                    <FormTitle>{title}</FormTitle>
                    {subText && <FormSubText>{subText}</FormSubText>}
                    {tick && <FormTick>{tick}</FormTick>}
                </div>
            )}
            <div className="radio">
                <Radio.Group disabled={disabled} onChange={onChange} value={value}>
                    <Space direction="vertical">
                        {options &&
                            options?.map((option: any, i) => (
                                <Radio key={i} disabled={option.disabled} value={option.value}>
                                    <FormTitle>{option.title}</FormTitle>
                                </Radio>
                            ))}
                    </Space>
                </Radio.Group>
            </div>
            {noteText && (
                <div>
                    <FormNoteText>{noteText}</FormNoteText>
                </div>
            )}
            {errorText && <div className="input-error-text">{errorText}</div>}
        </React.Fragment>
    );
};

export default RadioForm;
