import React from 'react';
import { Form, Input, Notification, PageTitle, Select, SelectBirthday, Skeleton } from '../../component';
import './SearchProfilePage.scss';
import './ListProfile.scss';
import ListProfile from './ListProfile';
import reState from '../../hooks/ReState';
import { listJobAPI } from '../../services/graphql/job';
import { listAddressAPI } from '../../services/graphql/address';
import { listInterestAPI } from '../../services/graphql/interest';
import {
    onHandleAddressSearchProfile,
    onHandleInterestSearchProfile,
    onHandleJobSearchProfile,
    onHandleSexSearchProfile,
    onHandleYearSearchProfile,
} from './functions';
import { profileAPI } from '../../services/graphql/profile';
import { Pagination, Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
// import useScrollBottom from '../../hooks/useScrollBottom';

const SearchProfilePage = () => {
    const {
        listDataJob,
        setListDataJob,
        listDataAddress,
        setListDataAddress,
        listDataInterest,
        setListDataInterest,
        stripeErrorPayment,
        profile,
    } = React.useContext(reState);
    const [listSearchProfile, setListSearchProfile] = React.useState();
    // const [isFetching] = useScrollBottom()
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const navigate = useNavigate();

    const [dataSearch, setDataSearch] = React.useState({
        name: null,
        nickname: null,
        address: null,
        job: null,
        interests: null,
        story: null,
        yearEnd: null,
        yearStart: null,
        sex: null,
    });

    React.useEffect(() => {
        const callAPI = async () => {
            if (!listDataJob) {
                const result = await listJobAPI({ keySearch: null });
                if (result && result.status === '200') {
                    setListDataJob(result.resul);
                }
            }
            if (!listDataAddress) {
                const result = await listAddressAPI({ keySearch: null });
                if (result && result.status === '200') {
                    setListDataAddress(result.resul);
                }
            }
            if (!listDataInterest) {
                const result = await listInterestAPI({ keySearch: null });
                if (result && result.status === '200') {
                    setListDataInterest(result.resul);
                }
            }
        };
        callAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (page > 0) {
            const callAPI = async () => {
                setLoading(true);
                const result = await profileAPI({ ...dataSearch, page: page, size: 9 });
                if (result && result.status === '200') {
                    setListSearchProfile(result.resul);
                }
                setLoading(false);
            };
            callAPI();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    React.useEffect(() => {
        if (profile && !profile.nickname) {
            navigate('/settings');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);

    return (
        <React.Fragment>
            
            <PageTitle title="ユーザー検索" />
            {stripeErrorPayment && (
                <>
                    <br />
                    <Notification
                        title="決済エラーの場合"
                        content={
                            <React.Fragment>
                                決済エラーの場合、ご利用可能なお支払い情報へ更新されますと自動的に決済が再開されます。
                            </React.Fragment>
                        }
                        line="default"
                        type="red"
                    />
                </>
            )}
            <br />
            <Form
                buttonEvent={async e => {
                    const newDataSearch = {
                        ...dataSearch,
                        ...e,
                        page: 1,
                        size: 9,
                    };
                    setLoading(true);
                    const result = await profileAPI(newDataSearch);
                    if (result && result.status === '200') {
                        setListSearchProfile(result.resul);
                        setTotal(result.total);
                        setPage(1);
                    } else {
                        setListSearchProfile();
                    }
                    setLoading(false);
                }}
                content={
                    <React.Fragment>
                        <br />
                        <div className="search-profile-100">
                            {/* <div className="search-profile-50">
                                <Input name="nickname" title="ニックネーム" placeholder="ショーヘイ" />
                            </div> */}
                            <div className="search-profile-50">
                                <Select
                                    onChange={e => onHandleAddressSearchProfile(e, setDataSearch, dataSearch)}
                                    name="address"
                                    placeholder="入力してください"
                                    title="地域"
                                    defaultValue={0}
                                    options={[
                                        {
                                            label: '選択',
                                            value: 0,
                                        },
                                        ...(listDataAddress && listDataAddress.length
                                            ? listDataAddress.map(value => {
                                                  return {
                                                      ...value,
                                                      label: value.name,
                                                      value: value.stt,
                                                  };
                                              })
                                            : []),
                                    ]}
                                    showSearch
                                />
                            </div>
                            <div className="search-profile-50">
                                <Select
                                    name="sex"
                                    placeholder="入力してください"
                                    title="性別"
                                    defaultValue={0}
                                    options={[
                                        {
                                            label: '選択',
                                            value: 0,
                                        },
                                        {
                                            label: '男性',
                                            value: 1,
                                        },
                                        { label: '女性', value: 2 },
                                    ]}
                                    onChange={e => onHandleSexSearchProfile(e, setDataSearch, dataSearch)}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="search-profile-100">
                            <div className="search-profile-50">
                            <Select
                                    name="interest"
                                    placeholder="入力してください"
                                    title="好きなジャンル"
                                    mode="multiple"
                                    options={[
                                        ...(listDataInterest && listDataInterest.length
                                            ? listDataInterest.map(value => {
                                                  return {
                                                      ...value,
                                                      label: value.name,
                                                      value: value.stt,
                                                  };
                                              })
                                            : []),
                                    ]}
                                    showSearch
                                    onChange={e => onHandleInterestSearchProfile(e, setDataSearch, dataSearch)}
                                />
                            </div>
                            <div className="search-profile-50">
                                <SelectBirthday
                                    name="year"
                                    placeholder="入力してください"
                                    title="年齢"
                                    defaultValue={0}
                                    onChange={e => onHandleYearSearchProfile(e, setDataSearch, dataSearch)}
                                />
                            </div>
                        </div>
                        <br />
                        {/* <div className="search-profile-100">
                            <div className="search-profile-50">
                                <Select
                                    name="job"
                                    placeholder="入力してください"
                                    title="はたらきかた"
                                    defaultValue={0}
                                    options={[
                                        {
                                            label: '選択',
                                            value: 0,
                                        },
                                        ...(listDataJob && listDataJob.length
                                            ? listDataJob.map(value => {
                                                  return {
                                                      ...value,
                                                      label: value.job,
                                                      value: value.stt,
                                                  };
                                              })
                                            : []),
                                    ]}
                                    showSearch
                                    onChange={e => onHandleJobSearchProfile(e, setDataSearch, dataSearch)}
                                />
                            </div>
                            <div className="search-profile-50">
                                <Select
                                    name="interest"
                                    placeholder="入力してください"
                                    title="好きなジャンル"
                                    mode="multiple"
                                    options={[
                                        ...(listDataInterest && listDataInterest.length
                                            ? listDataInterest.map(value => {
                                                  return {
                                                      ...value,
                                                      label: value.name,
                                                      value: value.stt,
                                                  };
                                              })
                                            : []),
                                    ]}
                                    showSearch
                                    onChange={e => onHandleInterestSearchProfile(e, setDataSearch, dataSearch)}
                                />
                            </div>
                        </div>
                        <br /> */}
                        {/* <div className="search-profile-50">
                            <Input name="story" title="自己紹介" placeholder="入力してください" />
                        </div> */}
                    </React.Fragment>
                }
                title="条件を入力して検索してください"
                // subTitle="気軽にフォローしてみましょう"
                buttonTitle="検索する"
                theme
            />
            <br />
            {listSearchProfile && listSearchProfile.length && !loading ? (
                <ListProfile {...{ listSearchProfile }} />
            ) : (
                ''
            )}
            {loading ? (
                <div className="list-profile">
                    {Array(6)
                        .fill('')
                        .map(i => (
                            <Skeleton key={i} className="profile-item" style={{ height: '150px' }} />
                        ))}
                </div>
            ) : listSearchProfile && listSearchProfile.length ? (
                ''
            ) : (
                <Result icon={<SmileOutlined />} title="結果が見つかりません" />
            )}

            <br />
            {listSearchProfile?.length ? (
                <div className="page-pagination">
                    <Pagination
                        disabled={loading}
                        pageSize={9}
                        current={page}
                        onChange={value => {
                            setPage(value);
                        }}
                        total={total}
                        showSizeChanger={false}
                    />
                </div>
            ) : (
                ''
            )}
        </React.Fragment>
    );
};

export default SearchProfilePage;
