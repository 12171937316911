// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
}
.page-title .page-title-size {
  font-size: 28px;
  font-weight: 600;
}
@media only screen and (max-width: 792px) {
  .page-title .page-title-size {
    font-size: 20px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/component/PageTitle/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;AACJ;AAAI;EACI,eAAA;EACA,gBAAA;AAER;AAAI;EACI;IACI,0BAAA;EAEV;AACF","sourcesContent":[".page-title{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    min-height: 60px;\n    .page-title-size{\n        font-size: 28px;\n        font-weight: 600;\n    }\n    @media only screen and (max-width: 792px) {\n        .page-title-size {\n            font-size: 20px !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
