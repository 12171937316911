import React from 'react';
import { Button, Notification, PageTitle, Tooltip } from '../../component';
import './HomePage.scss';
import { FaAddressCard, FaRss } from 'react-icons/fa';
import InfoImg from '../../assets/info.png';
import reState from '../../hooks/ReState';
import { useNavigate } from 'react-router-dom';
import { notificationsType } from '../../constants/home';
import { Modal } from 'antd';
import useResponsive from '../../hooks/useResponsive';

const HomePage = () => {
    const { profile, stripeNameSubscription, stripeErrorPayment } = React.useContext(reState);
    const navigation = useNavigate();
    const responsive = useResponsive()
    const notification = [
        {
            type: 'white',
            line: 'left',
            theme: 'primary',
            title: (
                <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                    <FaRss /> お知らせ
                </div>
            ),
            content: '左のメニューから選択してください 。',
        },
    ];
    React.useEffect(() => {
        document.body.style.overflowY = 'hidden';
        document.body.style.paddingBottom = '0px';
        // eslint-disable-next-line
    }, []);
    return (
        <React.Fragment>
            <Modal title="" centered open={stripeErrorPayment} closable={false} footer={null}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        justifyContent: 'center',
                        padding: '20px 30px',
                    }}>
                    <div style={{ color: 'red', fontSize: 30, fontWeight: '700' }}>決済エラーがあります</div>
                    <br />
                    <div
                        style={{
                            borderRadius: '4px',
                            border: '2px solid red',
                            padding: '30px 30px',
                            width: 'calc(100% - 20px)',
                            maxWidth: '500px',
                            fontSize: '20px',
                        }}>
                        至急、ご利用料金をお支払いください。
                    </div>
                    <br />
                    <Button
                        theme="error"
                        onClick={() => {
                            navigation(profile ? '/change-profile' : '/login');
                        }}
                        title="お支払い情報変更はこちらより"
                    />
                </div>
            </Modal>
            <PageTitle title="ホーム" />
            <div className="home-page-notification">
                {notification &&
                    notification.map((nItem, i) => {
                        return (
                            <Notification
                                title={nItem.title}
                                content={<span style={{ fontSize: '0.9rem' }}>{nItem.content}</span>}
                                theme={nItem.theme}
                                line={nItem.line}
                                type={nItem.type}
                                key={i}
                                hidden="mobile"
                            />
                        );
                    })}
                {/* {profile &&
                    profile.systemNotifications &&
                    profile.systemNotifications
                        .map(value => notificationsType(value))
                        .map((nItem, i) => {
                            if (nItem) {
                                return (
                                    <Notification
                                        title={nItem.title}
                                        content={<span style={{ fontSize: '0.9rem' }}>{nItem.content}</span>}
                                        theme={nItem.theme}
                                        line={nItem.line}
                                        type={nItem.type}
                                        key={i}
                                    />
                                );
                            }
                            return null;
                        })} */}
                {profile &&
                    profile.notifications &&
                    profile.notifications
                        .map(value => notificationsType(value))
                        .map((nItem, i) => {
                            if (nItem) {
                                return (
                                    <Notification
                                        title={nItem.title}
                                        content={<span style={{ fontSize: '0.9rem' }}>{nItem.content}</span>}
                                        theme={nItem.theme}
                                        line={nItem.line}
                                        type={nItem.type}
                                        key={i}
                                    />
                                );
                            }
                            return null;
                        })}
            </div>
            <div className="home-page-profile">
                <div className="home-page-profile-user">
                    <div className="home-page-profile-name">{profile && profile.nickname}</div>
                    {stripeNameSubscription && (
                        <div className="home-page-profile-nick-name">{stripeNameSubscription}</div>
                    )}
                </div>
                <div className="home-page-profile-avatar">
                    <img alt="画像" src={profile && profile.avatar} />
                </div>
                <div className="home-page-profile-list">
                    {/* <div className="home-page-profile-list-item">
                        <div>0</div>
                        <div className="home-page-profile-list-item-title">
                            ランク
                            <Tooltip
                                content="ランクが上がる様々な追加機能が利用可能になります。ランクアップパスを購入するとすぐにランクを上げることが出来ます。"
                                title="ランク">
                                <img alt="画像" src={InfoImg} />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="home-page-profile-list-line"></div>
                    <div className="home-page-profile-list-item">
                        <div>0</div>
                        <div className="home-page-profile-list-item-title">
                            ポイント
                            <Tooltip content="様々な特典に利用できるポイントです。" title="ポイント">
                                <img alt="画像" src={InfoImg} />
                            </Tooltip>
                        </div>
                    </div> */}
                    {/* <div className="home-page-profile-list-line"></div> */}
                    <div className="home-page-profile-list-item">
                        <div>{profile ? profile.sns_view : 0}</div>
                        <div className="home-page-profile-list-item-title">
                            SNS訪問者数
                            <Tooltip
                                type="right"
                                content="他のメンバーからSNSアカウントを閲覧された回数です。(重複は除きます)"
                                title="SNS訪問者数">
                                <img alt="画像" src={InfoImg} />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="home-page-profile-list-line"></div>
                    <div className="home-page-profile-list-item">
                        <div>{profile ? profile.sns_action : 0}</div>
                        <div className="home-page-profile-list-item-title">
                            本日のアクション数
                            <Tooltip
                                type={responsive ? "top" : 'right'}
                                content="他のメンバーのプロフィールまたはSNSアカウントを閲覧した回数です。(重複は除きます)"
                                title="本日のアクション数">
                                <img alt="画像" src={InfoImg} />
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="home-page-profile-button">
                    <Button
                        icon={<FaAddressCard />}
                        onClick={() => navigation('/' + (profile ? profile.id : ''))}
                        title="プロフィール確認"
                        style={{ width: '100%', fontWeight: '600' }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default HomePage;
