import React from 'react';
import './FollowUpPage.scss';
import { listOptions } from '../../services/listPack/listPack';
import { Modal, Result } from 'antd';
import { Button, Skeleton } from '../../component';
import { createOptionAPI } from '../../services/graphql/plan';
import reState from '../../hooks/ReState';
import StripePricingTable from './StripePricingTable';
import useResponsive from '../../hooks/useResponsive';

const FollowUpPage = () => {
    const reposnsive = useResponsive();
    // const { account } = React.useContext(reState);
    // const [listPack, setListPack] = React.useState([]);
    // const [option, setOption] = React.useState();
    // const [openModal, setOpenModal] = React.useState(false);
    // const [loading, setLoading] = React.useState(false);
    // const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
    // const [openErrorModal, setOpenErrorModal] = React.useState();

    // React.useEffect(() => {
    //     const listData = async () => {
    //         if (listPack.length === 0) {
    //             const data = await listOptions();
    //             if (data && data.length > 0 && !listPack.length) {
    //                 setListPack(data.reverse().filter(value => value.id !== process.env.REACT_APP_FREE_PLAN));
    //             }
    //         }
    //     };
    //     listData();
    //     // eslint-disable-next-line
    // }, []);

    // const onOpen = value => {
    //     setOption(value);
    //     setOpenModal(true);
    // };
    // const closeModel = () => {
    //     setOption();
    //     setOpenModal(false);
    //     setOpenSuccessModal(false);
    //     setOpenErrorModal();
    // };

    // const onSubmit = async () => {
    //     setLoading(true);
    //     const resul = await createOptionAPI({
    //         id: account?.id,
    //         plan: option?.default_price,
    //     });
    //     if (resul && resul.status === '200') {
    //         setOpenSuccessModal(true);
    //         setOpenErrorModal('');
    //     } else {
    //         setOpenErrorModal(resul.mess || '');
    //     }
    //     setLoading(false);
    // };

    return (
        <>
            <br />
            <br />

            {/* <Modal onOk={() => {}} onCancel={closeModel} centered open={openModal} closable={false} footer={null}>
                {openSuccessModal || openErrorModal ? (
                    <Result
                        status={openSuccessModal ? 'success' : 'error'}
                        title={openSuccessModal ? 'カード決済完了' : openErrorModal}
                        extra={[
                            <Button
                                onClick={() => closeModel()}
                                style={{ width: '100%' }}
                                title="キャンセル"
                                theme="info"
                            />,
                        ]}
                    />
                ) : (
                    <>
                        <h1 className="modal-text">フォローUPパス</h1>
                        <h4>
                            フォローUPパス購入から{option?.metadata?.day || ''}日間、フォローUPユーザーになります。
                            <br />
                            ※1回限り有効です。
                        </h4>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                            <Button
                                onClick={() => closeModel()}
                                style={{ width: '47%' }}
                                title="キャンセル"
                                theme="info"
                                disabled={loading}
                            />
                            <Button
                                disabled={loading}
                                loading={loading}
                                style={{ width: '47%' }}
                                title="購入"
                                theme="primary"
                                onClick={onSubmit}
                            />
                        </div>
                    </>
                )}
            </Modal> */}
            <br />
            <div style={{ ...(reposnsive ? { fontSize: '16px' } : {}) }} className="follow-up-page-title">
                {/* フォローUPユーザーになるにはフォローUPパスが必要です。
                <br />
                フォローUPユーザーになると、{reposnsive && <br />}目立つ場所にプロフィールが表示されてフォローがもらい
                {reposnsive && <br />}やすくなります。 */}
                フォローUPユーザーになるには<br/>
フォローUPパスが必要です。<br/>
フォローUPユーザーになると、<br/>
目立つ場所にプロフィールが表示されて<br/>
フォローがもらいやすくなります。<br/>
            </div>
            <StripePricingTable />
            {/* {listPack?.length ? (
                <div className="follow-up-page">
                    {listPack.map((value, key) => {
                        return (
                            <img
                                onClick={() => onOpen(value)}
                                alt="画像"
                                className="follow-up-page-img"
                                key={key}
                                src={value?.images[0]}
                            />
                        );
                    })}
                </div>
            ) : (
                <div className="follow-up-page">
                    <Skeleton style={{ height: '200px' }} />
                    <Skeleton style={{ height: '200px' }} />
                    <Skeleton style={{ height: '200px' }} />
                </div>
            )} */}
        </>
    );
};

export default FollowUpPage;
