import React from 'react';
import './SignPage.scss';
import { Button, Checkbox, Input, Select } from '../../component';
import { FaEnvelope, FaLock, FaUser } from 'react-icons/fa';
import { Modal, Spin } from 'antd';
import LoadInfoImg from '../../assets/load-info.png';
import { LoadingOutlined } from '@ant-design/icons';
import PayJPPage from './PayJPPage';
import { REGEX_EMAIL, REGEX_NOT_NAME_SPECIAL, REGEX_PASSWORD } from '../../constants/regex';
import { createAccount } from '../../services/graphql/account';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { createTokenStripe, listPackage } from '../../services/listPack/listPack';
import reState from '../../hooks/ReState';
import getStripe from './getStripe';
import defineMessage from '../../helper/defineErrorMessage';
import StripePricingTable from './StripePricingTable';

const SignPage = () => {
    // const [openModal, setOpenModal] = React.useState(false);
    // const [openModalPayJp, setOpenModalPayJp] = React.useState(false);
    // const { setAccount } = React.useContext(reState);
    // const [accept, setAccept] = React.useState(false);
    // const [loadList, setLoadList] = React.useState([]);
    // const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    // const [loading, setLoading] = React.useState(false);
    // const navigate = useNavigate();
    // const location = useLocation();
    // const [stripe_id, setStripe] = React.useState('');
    // const [dataStripe, setDataStripe] = React.useState({
    //     brand: '',
    //     expMonth: '',
    //     expYear: '',
    //     last4: '',
    // });
    // const [dataSign, setDataSign] = React.useState({
    //     account: '',
    //     fullName: '',
    //     password: '',
    //     rePassword: '',
    //     packId: '',
    // });
    // const [errorSign, setErrorSign] = React.useState({
    //     account: '',
    //     fullName: '',
    //     password: '',
    //     rePassword: '',
    //     packId: '',
    //     error: '',
    // });
    // const [listPack, setListPack] = React.useState([]);
    // const onChange = event => {
    //     const { name, value } = event.target;
    //     setDataSign({
    //         ...dataSign,
    //         [name]: value,
    //     });
    //     setErrorSign({
    //         account: '',
    //         fullName: '',
    //         password: '',
    //         rePassword: '',
    //         packId: '',
    //         error: '',
    //     });
    // };

    // React.useEffect(() => {
    //     const listData = async () => {
    //         if (listPack.length === 0) {
    //             const data = await listPackage();
    //             if (data && data.length > 0 && !listPack.length) {
    //                 setListPack(data.reverse());
    //                 if (!dataSign.packId) {
    //                     setDataSign({
    //                         ...dataSign,
    //                         packId: data[0].default_price,
    //                     });
    //                 }
    //                 setLoadList(data.reverse());
    //             }
    //         }
    //     };
    //     listData();
    //     document.body.style.overflowY = 'auto';
    //     document.body.style.paddingBottom = '70px';
    //     // eslint-disable-next-line
    // }, []);

    // const onCreateAccount = async () => {
    //     const { fullName, password, account, rePassword, packId } = dataSign;
    //     if (!fullName || fullName.trim().length < 1) {
    //         setErrorSign({
    //             ...errorSign,
    //             fullName: 'お客様の氏名をご入力ください',
    //         });
    //     } else if (REGEX_NOT_NAME_SPECIAL.test(fullName)) {
    //         setErrorSign({
    //             ...errorSign,
    //             fullName: '姓名に特殊文字を含めることはできません',
    //         });
    //     } else if (fullName > 45) {
    //         setErrorSign({
    //             ...errorSign,
    //             fullName: '姓名は 45 文字以内にする必要があります',
    //         });
    //     } else if (!account || account.trim().length < 1) {
    //         setErrorSign({
    //             ...errorSign,
    //             account: 'アカウントを空にすることはできません',
    //         });
    //     } else if (!REGEX_EMAIL.test(account)) {
    //         setErrorSign({
    //             ...errorSign,
    //             account: 'アカウントのメール形式が正しくありません',
    //         });
    //     } else if (!password || password.trim().length < 1) {
    //         setErrorSign({
    //             ...errorSign,
    //             password: 'パスワードを空白にすることはできません',
    //         });
    //     } else if (password.trim().length < 6) {
    //         setErrorSign({
    //             ...errorSign,
    //             password: 'パスワードは 6 文字以上必要です',
    //         });
    //     } else if (!REGEX_PASSWORD.test(password)) {
    //         setErrorSign({
    //             ...errorSign,
    //             password: 'パスワードはフォーマットされていません。A-Za-z0-9.!,?*%$#@ の範囲の文字を使用してください。',
    //         });
    //     } else if (!rePassword || rePassword.trim().length < 1) {
    //         setErrorSign({
    //             ...errorSign,
    //             rePassword: '再入力するパスワードは空白にできません',
    //         });
    //     } else if (!REGEX_PASSWORD.test(rePassword) || rePassword !== password) {
    //         setErrorSign({
    //             ...errorSign,
    //             rePassword: '再入力したパスワードが正しくありません',
    //         });
    //     } else if (!stripe_id) {
    //     } else {
    //         setOpenModal(true);
    //         setTimeout(async () => {
    //             const { brand, expMonth, expYear, last4 } = dataStripe;
    //             const result = await createAccount({
    //                 fullName,
    //                 account,
    //                 password,
    //                 packId,
    //                 stripe_id,
    //                 brand,
    //                 expMonth,
    //                 expYear,
    //                 last4,
    //             });
    //             if (result.status === '200') {
    //                 const stripe = await getStripe();
    //                 if (result.resul[0].need_3d_authen) {
    //                     const { error } = await stripe.confirmCardSetup(result.resul[0].client_secret);
    //                     if (error) {
    //                         setOpenModal(false);
    //                         setErrorSign({
    //                             ...errorSign,
    //                             error: defineMessage(error),
    //                         });
    //                         setLoading(false);
    //                         return;
    //                     } else {
    //                         const response = await createAccount({
    //                             fullName,
    //                             account,
    //                             password,
    //                             packId,
    //                             stripe_id,
    //                             brand,
    //                             expMonth,
    //                             expYear,
    //                             last4,
    //                             setupIntentSuccess: true,
    //                             customer_id: result.resul[0].customer_id,
    //                         });

    //                         if (response.status === '200') {
    //                             sessionStorage.setItem('__at', response.token);
    //                             localStorage.setItem('__ft', response.refreshToken);
    //                             localStorage.setItem('__ip', response.resul[0].id);
    //                             setAccount({
    //                                 at: response.token,
    //                                 ft: response.refreshToken,
    //                                 id: response.resul[0].id,
    //                             });
    //                             setOpenModal(false);
    //                             navigate('/register/success', {
    //                                 state: 'create-success',
    //                             });
    //                             setLoading(false);
    //                         } else {
    //                             setErrorSign({
    //                                 ...errorSign,
    //                                 error: response.mess,
    //                             });
    //                             if (response.status === 'ERROR_1008') {
    //                                 const { cvc, exp_month, exp_year, name, number } = dataStripe;
    //                                 const token = await createTokenStripe({
    //                                     cvc,
    //                                     exp_month,
    //                                     name,
    //                                     number,
    //                                     exp_year: '20' + exp_year,
    //                                 });
    //                                 if (token.id) {
    //                                     setStripe(token.id);
    //                                 }
    //                             }
    //                             setOpenModal(false);
    //                             setLoading(false);
    //                         }
    //                     }
    //                 }
    //             } else {
    //                 setErrorSign({
    //                     ...errorSign,
    //                     error: result.mess,
    //                 });

    //                 if (result.status === 'ERROR_1008') {
    //                     const { cvc, exp_month, exp_year, name, number } = dataStripe;
    //                     const token = await createTokenStripe({
    //                         cvc,
    //                         exp_month,
    //                         name,
    //                         number,
    //                         exp_year: '20' + exp_year,
    //                     });
    //                     if (token.id) {
    //                         setStripe(token.id);
    //                     }
    //                 }
    //                 setOpenModal(false);
    //                 setLoading(false);
    //             }
    //         }, 10);
    //     }
    // };

    // React.useEffect(() => {
    //     if (location.search && listPack?.length) {
    //         const keyPlan = location.search.split('&')[0] ? location.search.split('&')[0].replace('?plan=', '') : '';
    //         if (keyPlan) {
    //             let newData = [];
    //             if (keyPlan === process.env.REACT_APP_FREE_PLAN) {
    //                 for (let index = 0; index < listPack.length; index++) {
    //                     if (listPack[index].id === process.env.REACT_APP_FREE_PLAN) {
    //                         newData.push(listPack[index]);
    //                     }
    //                 }
    //             }

    //             if (!newData.length) {
    //                 for (let index = 0; index < listPack.length; index++) {
    //                     if (listPack[index].id !== process.env.REACT_APP_FREE_PLAN) {
    //                         newData.push(listPack[index]);
    //                     }
    //                 }
    //             }

    //             const filterPlan = newData.filter(plan => plan.id === keyPlan)[0];
    //             setListPack(newData);
    //             if (filterPlan) {
    //                 setDataSign({
    //                     ...dataSign,
    //                     packId: filterPlan.default_price,
    //                 });
    //             }
    //         }
    //     } else {
    //         if (listPack?.length) {
    //             let newData = [];
    //             for (let index = 0; index < listPack.length; index++) {
    //                 if (listPack[index].id !== process.env.REACT_APP_FREE_PLAN) {
    //                     newData.push(listPack[index]);
    //                 }
    //             }
    //             setListPack(newData);
    //             setDataSign({
    //                 ...dataSign,
    //                 packId: newData[0].default_price,
    //             });
    //         }
    //     }
    //     // eslint-disable-next-line
    // }, [location, loadList]);

    // React.useEffect(() => {}, []);
    return (
        <React.Fragment>
            <StripePricingTable />
            {/* <Modal
                onOk={() => setOpenModalPayJp(false)}
                onCancel={() => setOpenModalPayJp(false)}
                title=""
                centered
                open={openModalPayJp}
                closable={false}
                footer={null}>
                <PayJPPage {...{ setStripe, setOpenModalPayJp, setDataStripe, setErrorSign }} />
            </Modal>
            <Modal
                onOk={() => {}}
                onCancel={() => {}}
                title=""
                centered
                open={openModal}
                closable={false}
                footer={null}>
                <div className="modal-img">
                    <img alt="画像" src={LoadInfoImg} />
                </div>
                <h1 className="modal-text">登録中です。</h1>
                <h4 className="modal-text">このまましばらくお待ちください。</h4>
                <h4 className="modal-text">
                    <Spin indicator={antIcon} />
                </h4>
            </Modal>
            <div className="sign-page-title">登録</div>
            {errorSign.error && (
                <React.Fragment>
                    <br />
                    <div className="sign-page-error" dangerouslySetInnerHTML={{ __html: errorSign.error }}></div>
                </React.Fragment>
            )}
            <br />
            <Input
                error={errorSign.fullName}
                errorText={errorSign.fullName}
                onChange={onChange}
                value={dataSign.fullName}
                name="fullName"
                icon={<FaUser />}
                placeholder="お名前"
            />
            <br />
            <Input
                error={errorSign.account}
                errorText={errorSign.account}
                onChange={onChange}
                name="account"
                value={dataSign.account}
                icon={<FaEnvelope />}
                placeholder="メールアドレス"
            />
            <br />
            <Input
                error={errorSign.password}
                errorText={errorSign.password}
                onChange={onChange}
                name="password"
                type="password"
                value={dataSign.password}
                icon={<FaLock />}
                placeholder="パスワード"
            />
            <br />
            <Input
                error={errorSign.rePassword}
                errorText={errorSign.rePassword}
                onChange={onChange}
                name="rePassword"
                value={dataSign.rePassword}
                icon={<FaLock />}
                type="password"
                placeholder="パスワード（確認）"
                noteText={
                    <React.Fragment>
                        マイページにログイン用のパスワードです。
                        <br />
                        お好きなパスワードを設定してください
                    </React.Fragment>
                }
            />
            <br />
            <Select
                onChange={e => setDataSign({ ...dataSign, packId: e })}
                placeholder="入力してください"
                value={dataSign.packId || null}
                options={listPack.map(pack => {
                    return {
                        ...pack,
                        label: `${pack?.name}`,
                        value: pack?.default_price,
                    };
                })}
                showSearch
                disabled={listPack.length > 0 ? false : true}
                noteText="料金プランを選択してください"
            />
            <br />
            <Button
                onClick={() => setOpenModalPayJp(true)}
                theme={stripe_id ? 'primary' : 'info'}
                title={stripe_id ? '入力完了' : 'お支払い情報を入力'}
                style={{ width: '100%' }}
            />
            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Checkbox
                    options={[
                        {
                            label: (
                                <React.Fragment>
                                    <span
                                        onClick={() => window.open('https://insta-style.club/pages/terms', '_blank')}
                                        className="sign-page-checkbox">
                                        利用規約
                                    </span>
                                    に同意する
                                </React.Fragment>
                            ),
                            value: 1,
                            checked: accept,
                        },
                    ]}
                    onChange={e => setAccept(!e.checked)}
                />
                <Link className="sign-page-link" to={'/login'}>
                    ログインページ
                </Link>
            </div>
            <br />
            <Button
                onClick={() => onCreateAccount()}
                title={
                    stripe_id && dataSign.account && dataSign.fullName && dataSign.password && dataSign.rePassword
                        ? '登録'
                        : '未入力項目があります'
                }
                disabled={loading ? loading : !accept}
                theme={
                    stripe_id && dataSign.account && dataSign.fullName && dataSign.password && dataSign.rePassword
                        ? 'primary'
                        : 'info'
                }
                style={{ width: '100%' }}
            /> */}

            {/*<div style={{ textAlign: 'center' }}>
                <Link className="sign-page-link" to={'/login'}>
                    ログインページ
                </Link>
        </div>*/}
        </React.Fragment>
    );
};

export default SignPage;
