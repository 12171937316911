import React from 'react';
import ChangePassword from './tab/ChangePassword';
import ChangePayment from './tab/ChangePayment';
import ChangeEmail from './tab/ChangeEmail';
import reState from '../../hooks/ReState';
import ChangePlan from './tab/ChangePlan';

const ProfileRegisterPage = () => {
    const { stripeErrorPayment } = React.useContext(reState);
    return (
        <React.Fragment>
            {!stripeErrorPayment && (
                <>
                <br />
                    <ChangePlan />
                    <br />
                    <ChangeEmail />
                    <br />
                    <ChangePassword />
                </>
            )}
            <br />
            <ChangePayment />
        </React.Fragment>
    );
};

export default ProfileRegisterPage;
