import client from '../../apollo-client';
import { gql } from '@apollo/client';

type TypeProps = {
    oldEmail?: String;
    newEmail?: String;
    token?: String;
};

export const changeEmailAPI = async ({ oldEmail = '', newEmail = '' }: TypeProps) => {
    const changeEmailId = localStorage.getItem('__ip');
    const data: any = await client.mutate({
        mutation: gql`
            mutation ChangeEmail($oldEmail: String!, $newEmail: String!, $changeEmailId: ID!) {
                changeEmail(oldEmail: $oldEmail, newEmail: $newEmail, id: $changeEmailId) {
                    resul
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            oldEmail,
            newEmail,
            changeEmailId,
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.changeEmail ?? {};
};
export const acceptEmailAPI = async ({ token = '' }: TypeProps) => {
    const data: any = await client.mutate({
        mutation: gql`
            mutation AcceptEmail($token: String) {
                acceptEmail(token: $token) {
                    resul
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            token,
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.acceptEmail ?? {};
};
