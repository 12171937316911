const StripeErrorTypes = {
  insufficient_funds: "決済エラー:残高不足",
  amount_too_large: "決済エラー:限度額オーバー",
  expired_card: "決済エラー:期限切れ",
  payment_method_bank_account_blocked: "決済エラー:許可されない取引",
  incorrect_number: "決済エラー:カード番号の誤り",
  invalid_number: "決済エラー:カード番号の誤り",
  incorrect_cvc: "決済エラー:セキュリティコードの誤り",
  invalid_cvc: "決済エラー:セキュリティコードの誤り",
  withdrawal_count_limit_exceeded: '決済エラー:残高不足',
};

export default StripeErrorTypes;