import client from '../../apollo-client';
import { gql } from '@apollo/client';

type TypeProps = {
    fullName?: string;
    account?: string;
    password?: string;
    packId?: string;
    stripe_id?: String;
    last4?: String;
    brand?: String;
    expMonth?: number;
    expYear?: number;
    email?: string;
    token?: string;
    setupIntentSuccess?: boolean;
    customer_id?: string;
};

export const createAccount = async ({
    fullName = '',
    packId = '',
    account = '',
    password = '',
    stripe_id = '',
    brand,
    expMonth,
    expYear,
    last4,
    setupIntentSuccess,
    customer_id,
}: TypeProps) => {
    const data: any = await client.mutate({
        mutation: gql`
            mutation CreateAccount(
                $fullName: String
                $account: String
                $password: String
                $packId: String
                $stripe_id: String
                $last4: String
                $brand: String
                $expMonth: Int
                $expYear: Int
                $setupIntentSuccess: Boolean
                $customer_id: String
            ) {
                createAccount(
                    full_name: $fullName
                    account: $account
                    password: $password
                    pack_id: $packId
                    stripe_id: $stripe_id
                    last4: $last4
                    brand: $brand
                    exp_month: $expMonth
                    exp_year: $expYear
                    setupIntentSuccess: $setupIntentSuccess
                    customer_id: $customer_id
                ) {
                    resul {
                        id
                        username
                        full_name
                        create_time
                        status
                        client_secret
                        need_3d_authen
                        customer_id
                    }
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            fullName,
            packId,
            account,
            password,
            stripe_id,
            brand,
            expMonth,
            expYear,
            last4,
            setupIntentSuccess,
            customer_id,
        },
    });

    return data?.data?.createAccount ?? {};
};

export const loginAccount = async ({ account = '', password = '' }: TypeProps) => {
    const data: any = await client.mutate({
        mutation: gql`
            mutation Login($account: String, $password: String) {
                login(account: $account, password: $password) {
                    resul {
                        id
                        username
                        full_name
                        create_time
                        status
                    }
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            account,
            password,
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.login ?? {};
};

export const sendEmailRemoveAccount = async ({ email = '' }: TypeProps) => {
    const data: any = await client.mutate({
        mutation: gql`
            mutation SendEmailRemoveAccount($email: String) {
                sendEmailRemoveAccount(email: $email) {
                    resul
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            email,
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.sendEmailRemoveAccount ?? {};
};

export const acceptRemoveAccount = async ({ token = '', account = '', password = '' }: TypeProps) => {
    const data: any = await client.mutate({
        mutation: gql`
            mutation AcceptRemoveAccount($token: String, $account: String, $password: String) {
                acceptRemoveAccount(token: $token, account: $account, password: $password) {
                    resul
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            token,
            account,
            password,
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.acceptRemoveAccount ?? {};
};

export const checkTokenRemoveAccount = async ({ token = '' }: TypeProps) => {
    const data: any = await client.mutate({
        mutation: gql`
            mutation CheckTokenRemoveAccount($token: String) {
                checkTokenRemoveAccount(token: $token) {
                    resul
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            token,
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.checkTokenRemoveAccount ?? {};
};
