import React, { useEffect } from 'react';

export const useMenu = () => {
    const [openMenu, setOpenMenu] = React.useState(window.innerWidth < 792 ? undefined : null);
    const [newWidth, setNewWidth] = React.useState(window.innerWidth);
    useEffect(() => {
        window.addEventListener('resize', () => {
            setNewWidth(window.innerWidth);
        });
        // eslint-disable-next-line
    }, [window]);
    
    return { openMenu, setOpenMenu, newWidth };
};
