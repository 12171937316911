import React from 'react';
import './style.scss';

type TypeProps = React.HTMLAttributes<HTMLDivElement> & {
    children?: React.ReactNode;
    title?: React.ReactNode;
    content?: React.ReactNode;
    type?: 'right' | 'left' | 'top'
};

const Tooltip = ({ children, title, content, type = 'right' }: TypeProps) => {
    const [open, setOpen] = React.useState(false);
    return (
        <span onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)} className="tooltip">
            {children}
            {open && (
                <div className={`tooltip-container tooltip-${type}`}>
                    <div className='tooltip-title'>{title}</div>
                    <div className='tooltip-content'>{content}</div>
                </div>
            )}
        </span>
    );
};

export default Tooltip;
