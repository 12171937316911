import React from 'react';
import Logo from '../../assets/logo.png';
import './ForgotPasswordPage.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import reState from '../../hooks/ReState';
import { Button, Input, Notification } from '../../component';
import { FaEnvelope, FaLock } from 'react-icons/fa';
import {
    changeForgotPasswordAPI,
    checkTokenForgotPasswordAPI,
    confirmEmailForgotPasswordAPI,
} from '../../services/graphql/forgot-password';
import { REGEX_PASSWORD } from '../../constants/regex';
import { Modal } from 'antd';

const ForgotPasswordPage = () => {
    const { profile } = React.useContext(reState);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState();
    const [success, setSuccess] = React.useState();
    const [email, setEmail] = React.useState('');
    const [token, setToken] = React.useState('');
    const [oldPassword, setOldPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [openModal, setOpenModal] = React.useState(false);
    const route = useLocation();
    const history = useNavigate();
    React.useEffect(() => {
        if (profile) {
            history('/');
        }
        // eslint-disable-next-line
    }, [profile]);
    React.useEffect(() => {
        document.body.style.overflowY = 'auto';
        document.body.style.paddingBottom = '70px';
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (route.search) {
            const tokenUrl = route.search.replace('?key=', '') || '';
            if (tokenUrl) {
                const callApiCheckToken = async () => {
                    setLoading(true);
                    const result = await checkTokenForgotPasswordAPI({ token: tokenUrl });
                    if (result && result.status === '200') {
                        setToken(tokenUrl);
                    } else {
                        setError(result.mess || '');
                    }
                    setLoading(false);
                };
                callApiCheckToken();
            }
        }
        // eslint-disable-next-line
    }, [route]);

    const onSubmit = async () => {
        if (email && email.trim()) {
            setLoading(true);
            const result = await confirmEmailForgotPasswordAPI({ account: email });
            if (result && result.status === '200') {
                setSuccess(
                    'パスワード再設定用のURLをご入力のメールアドレスに送信しました。<br/>記載された内容に従って、パスワードの再設定を行なってください。',
                );
                setEmail('');
            } else {
                setError(result?.mess || '');
            }
            setLoading(false);
        } else {
            setError('アカウントを入力してください。');
        }
    };

    const onUpdatePassword = async () => {
        if (!oldPassword || !oldPassword.trim()) {
            setError('パスワードを空白にすることはできません。');
        } else if (!REGEX_PASSWORD.test(oldPassword)) {
            setError('パスワードの形式が正しくありません。');
        } else if (oldPassword !== newPassword) {
            setError('再入力したパスワードが間違っています。');
        } else {
            setLoading(true);
            const result = await changeForgotPasswordAPI({
                password: oldPassword,
                token: token,
            });
            if (result && result.status === '200') {
                setOpenModal(true);
            } else {
                setError(result?.mess || '');
            }
            setLoading(false);
        }
    };
    const closeModel = () => {
        setOpenModal(false);
        history('/login');
    };
    return (
        <React.Fragment>
            <Modal onOk={() => {}} onCancel={closeModel} centered open={openModal} closable={false} footer={null}>
                <h3 style={{ textAlign: 'center' }}>パスワード変更完了</h3>
                <br />
                パスワードの変更が完了しました。
                <br />
                新しいパスワードでログインしてください。
                <br />
                <br />
                <Button style={{ width: '100%' }} onClick={closeModel} title="ログインページに戻る" />
            </Modal>
            <>
                <br />
                <br />
                <div className="forgot-password-page-logo">
                    <img alt="画像" src={Logo} />
                </div>
                <div className="forgot-password-page">
                    <h3 style={{ textAlign: 'center' }}>
                        {token ? 'パスワード変更' : success ? 'メール送信完了' : 'パスワードを変更する'}
                    </h3>
                    {success && (
                        <>
                            <Notification line="default" type="green" titleString={success} />
                            <br />
                        </>
                    )}
                    {error && (
                        <>
                            <Notification line="default" type="red" titleString={error} />
                            <br />
                        </>
                    )}
                    {token ? (
                        <>
                            <Input
                                disabled={loading}
                                icon={<FaLock />}
                                onChange={e => {
                                    setOldPassword(e.target.value);
                                }}
                                value={oldPassword}
                                name="oldPassword"
                                placeholder="新しいパスワード"
                                type="password"
                            />
                            <br />
                            <Input
                                onChange={e => {
                                    setNewPassword(e.target.value);
                                }}
                                value={newPassword}
                                icon={<FaLock />}
                                name="newPassword"
                                placeholder="新しいパスワード（確認)"
                                type="password"
                            />
                            <br />
                            <Button
                                disabled={loading}
                                loading={loading}
                                onClick={onUpdatePassword}
                                title="更新する"
                                style={{ width: '100%' }}
                            />
                        </>
                    ) : (
                        !success && (
                            <>
                                <div>
                                    ご登録いただいたメールアドレスを入力してください。
                                    <br />
                                    メールアドレス宛にパスワード変更ページのURLが記載されたメールを送信します。
                                </div>
                                <br />
                                <Input
                                    value={email}
                                    onChange={e => {
                                        setEmail(e.target.value);
                                        setError();
                                        setSuccess();
                                    }}
                                    disabled={loading}
                                    icon={<FaEnvelope />}
                                    name="email"
                                    placeholder="ご登録のメールアドレス"
                                />
                                <br />
                                <Button
                                    disabled={!email ? true : loading}
                                    loading={loading}
                                    onClick={onSubmit}
                                    title="パスワード再設定"
                                    style={{ width: '100%' }}
                                />
                            </>
                        )
                    )}
                    <br />
                    <Link
                        style={{
                            color: 'rgb(255 150 206)',
                            textAlign: 'center',
                            textDecoration: 'none',
                            fontWeight: '600',
                            fontSize: '18px',
                        }}
                        to={'/login'}>
                        ログインページ
                    </Link>
                </div>
            </>
        </React.Fragment>
    );
};

export default ForgotPasswordPage;
