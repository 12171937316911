import React from 'react';
import './style.scss';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

type TypeProps = {
    title?: string;
    icon?: React.ReactNode;
    onClick?: (arg?: any) => any;
    theme?: 'primary' | 'info' | 'error';
    disabled?: boolean;
    style?: React.CSSProperties
    loading?: boolean
};

const index = ({ title, icon, onClick = () => {}, theme = 'primary', disabled = false, style, loading = false }: TypeProps) => {
    const handleClick = (arg: any) => {
        if (!disabled) {
            onClick(arg);
        }
    };
    const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
    return (
        <button style={style} type='button' onClick={handleClick} className={`button button-${theme} ${disabled && 'button-disabled'}`}>
            {icon}
            {title}
            {loading && <Spin indicator={antIcon} />}
        </button>
    );
};

export default index;
