// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton-wrapper {
  animation: pulse 700ms infinite alternate;
  display: inline-block;
}

@keyframes pulse {
  from {
    background-color: rgba(158, 244, 255, 0.5058823529);
  }
  to {
    background-color: rgba(87, 197, 216, 0.4196078431);
  }
}`, "",{"version":3,"sources":["webpack://./src/component/Skeleton/style.scss"],"names":[],"mappings":"AAAA;EACI,yCAAA;EACA,qBAAA;AACJ;;AACA;EACI;IACI,mDAAA;EAEN;EAAE;IACI,kDAAA;EAEN;AACF","sourcesContent":[".skeleton-wrapper {\n    animation: pulse 700ms infinite alternate;\n    display: inline-block;\n}\n@keyframes pulse {\n    from {\n        background-color: #9ef4ff81;\n    }\n    to {\n        background-color: #57c5d86b;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
