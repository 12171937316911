// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page-70 {
  width: calc(60% - 10px);
  display: flex;
  align-items: center;
}

.login-page-30 {
  width: calc(40% - 10px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.login-page-100 {
  display: flex;
  gap: 20px;
}

.login-page-title {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page-note {
  color: rgb(255, 150, 206);
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.login-page-error {
  background-color: rgb(255, 62, 62);
  color: white;
  font-weight: 500;
  padding: 10px;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/pages/register/LoginPage.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AACA;EACI,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AAEJ;;AAAA;EACI,aAAA;EACA,SAAA;AAGJ;;AADA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAIJ;;AAFA;EACI,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;AAKJ;;AAFA;EACI,kCAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;AAKJ","sourcesContent":[".login-page-70 {\n    width: calc(60% - 10px);\n    display: flex;\n    align-items: center;\n}\n.login-page-30 {\n    width: calc(40% - 10px);\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n}\n.login-page-100 {\n    display: flex;\n    gap: 20px;\n}\n.login-page-title{\n    height: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.login-page-note{\n    color:rgb(255 150 206);\n    font-weight: 500;\n    font-size: 14px;\n    text-align: center;\n    cursor: pointer;\n\n} \n.login-page-error{\n    background-color: rgb(255, 62, 62);\n    color: white;\n    font-weight: 500;\n    padding: 10px;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
