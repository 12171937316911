// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-tick {
  background-color: rgb(219, 51, 68);
  border-radius: 4px;
  padding: 3px 4px;
  color: white;
  margin-right: 3px;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/component/Tick/style.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;AACJ","sourcesContent":[".ui-tick{\n    background-color: rgb(219, 51, 68);\n    border-radius: 4px;\n    padding: 3px 4px;\n    color: white;\n    margin-right: 3px;\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
