import LogoInstagram from '../assets/instagram.png';
import LogoTikTok from '../assets/tik-tok.png';
import LogoTwitter from '../assets/twitter.png';
import LogoYoutube from '../assets/youtube.png';

export const logoNetwork = {
    instagram: LogoInstagram,
    'tik-tok': LogoTikTok,
    twitter: LogoTwitter,
    youtube: LogoYoutube,
};