import React from 'react';
import './App.scss';
import AppRouter from './config/AppRouter';
import { ContextProvider } from './hooks/ReState';

function App() {
    const [status, setStatus] = React.useState(false);
    // Not connect
    React.useEffect(() => {
        if (window.location.hostname === 'app.o-mochi.jp' || window.location.hostname === "52.203.119.56" || window.location.hostname === "52.45.118.106" || window.location.hostname === "o-mochi-alb-461469692.us-east-1.elb.amazonaws.com") {
            setStatus(false);
            var link = document.querySelector("link[rel='icon']") || document.createElement('link');
            link.type = 'image/png';
            link.href = 'https://w7.pngwing.com/pngs/859/845/png-transparent-earth-symbol-globe-computer-icons-earth-globe-logo-monochrome-thumbnail.png';
            var link2 = document.querySelector("link[rel='apple-touch-icon']") || document.createElement('link');
            link2.href = 'https://w7.pngwing.com/pngs/859/845/png-transparent-earth-symbol-globe-computer-icons-earth-globe-logo-monochrome-thumbnail.png';
            var link3 = document.querySelector("link[rel='manifest']") || document.createElement('link');
            link3.href = 'https://w7.pngwing.com/pngs/859/845/png-transparent-earth-symbol-globe-computer-icons-earth-globe-logo-monochrome-thumbnail.png';
            document.getElementsByTagName('head')[0].appendChild(link);
            document.getElementsByTagName('head')[0].appendChild(link2);
            document.getElementsByTagName('head')[0].appendChild(link3);
            document.title = "Error"
        } else {
            setStatus(true);
            document.title = "insta-style"
        }
    }, [window]);
    return (
        <React.Fragment>
            <ContextProvider>{status ? <AppRouter /> : ''}</ContextProvider>
        </React.Fragment>
    );
}

export default App;
