// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  min-height: 50px;
  outline: none;
  border: none;
  padding: 10px 23px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  font-size: 17px;
}

.button:hover {
  opacity: 0.8;
}

.button:active {
  opacity: 1;
  box-shadow: 0px 0px 3px 1px rgba(128, 128, 128, 0.658);
}

.button-disabled {
  opacity: 0.6 !important;
  cursor: no-drop;
}

.button-disabled:active {
  cursor: no-drop;
  box-shadow: none;
}

.button-primary {
  background-color: rgb(252, 92, 180);
  color: white;
}

.button-info {
  background-color: rgb(48, 159, 215);
  color: white;
}

.button-error {
  background-color: rgb(255, 0, 0);
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/component/Button/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,uBAAA;EACA,eAAA;AACJ;;AACA;EACI,YAAA;AAEJ;;AAAA;EACI,UAAA;EACA,sDAAA;AAGJ;;AADA;EACI,uBAAA;EACA,eAAA;AAIJ;;AAFA;EACI,eAAA;EACA,gBAAA;AAKJ;;AAHA;EACI,mCAAA;EACA,YAAA;AAMJ;;AAJA;EACI,mCAAA;EACA,YAAA;AAOJ;;AALA;EACI,gCAAA;EACA,YAAA;AAQJ","sourcesContent":[".button {\n    min-height: 50px;\n    outline: none;\n    border: none;\n    padding: 10px 23px;\n    border-radius: 3px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    gap: 4px;\n    justify-content: center;\n    font-size: 17px;\n}\n.button:hover {\n    opacity: 0.8;\n}\n.button:active {\n    opacity: 1;\n    box-shadow: 0px 0px 3px 1px rgba(128, 128, 128, 0.658);\n}\n.button-disabled {\n    opacity: 0.6 !important;\n    cursor: no-drop;\n}\n.button-disabled:active {\n    cursor: no-drop;\n    box-shadow: none;\n}\n.button-primary {\n    background-color: rgb(252, 92, 180);\n    color: white;\n}\n.button-info {\n    background-color: rgb(48 159 215);\n    color: white;\n}\n.button-error {\n    background-color: rgb(255, 0, 0);\n    color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
