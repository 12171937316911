import React from 'react';
import './LayoutSideBar.scss';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useMenu } from './hooks';
import LogoImg from 'src/assets/logo.png';
// import WarningImg from 'src/assets/error-search.png';
import { menuListBar, menuType } from 'src/constants/menu';
import { Link, useLocation } from 'react-router-dom';
// import { MdOutlineNotificationsNone } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Modal, Popover } from 'antd';
import reState from '../hooks/ReState';
import { Button } from '../component';

const LayoutSideBar = props => {
    const { openMenu, setOpenMenu, newWidth } = useMenu();
    const { setProfile, setAccount, profile, stripeErrorPayment, setlistProfileCommunity, setlistTodayNews } =
        React.useContext(reState);
    const [openModal, setOpenModal] = React.useState(false);
    const [openModalErrorCard, setOpenModalErrorCard] = React.useState(false);
    const route = useLocation();
    const navigate = useNavigate();
    const onHandleClearOpen = () => {
        if (newWidth < 792 && openMenu !== undefined) {
            setOpenMenu(false);
        }
    };
    const onCheckAccess = (e, item) => {
        if ((stripeErrorPayment && item.checked) || (item.validateStripe && stripeErrorPayment)) {
            setOpenModalErrorCard(true);
            e.preventDefault();
            e.stopPropagation();
        } else if (item.checked && !profile?.nickname) {
            setOpenModal(true);
            e.preventDefault();
            e.stopPropagation();
        } else {
            onHandleClearOpen();
        }
    };

    const profileType = menuItem => {
        if (menuItem.to === '/search-profile') {
            const currentPath = route.pathname;
            const pattern = /^\/([0-9]+)$/;
            if (pattern.test(currentPath)) {
                const userId = currentPath.substring(1);
                return userId === profile?.id?.toString() ? 'current' : 'other';
            }
        }
        return null;
    };

    const isMyProfile = () => {
        const currentPath = route.pathname;
        const pattern = /^\/([0-9]+)$/;
        const userId = currentPath.substring(1);
        return pattern.test(currentPath) && userId === profile?.id?.toString();
    };

    return (
        <div className="layout-sidebar">
            <Modal
                onOk={() => setOpenModal(false)}
                onCancel={() => setOpenModal(false)}
                title=""
                centered
                open={openModal}
                closable={false}
                footer={null}>
                <IconWarning />
                <div className="warning-click-tab-search">
                    {/* <img src={WarningImg} alt="error" /> */}
                    <br />
                    <div className="warning-click-tab-search-title">プロフィールを設定してください!</div>
                    <br />
                    <div className="warning-click-tab-search-content">
                    フォロワーやいいねを増やしていくためには<br/>
あなたのプロフィールを充実させましょう!
                    </div>
                </div>
            </Modal>
            <Modal
                onOk={() => setOpenModalErrorCard(false)}
                onCancel={() => setOpenModalErrorCard(false)}
                title=""
                centered
                open={openModalErrorCard}
                closable={false}
                footer={null}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        justifyContent: 'center',
                        padding: '20px 30px',
                    }}>
                    <div style={{ color: 'red', fontSize: 30, fontWeight: '700' }}>決済エラーがあります</div>
                    <br />
                    <div
                        style={{
                            borderRadius: '4px',
                            border: '2px solid red',
                            padding: '30px 30px',
                            width: 'calc(100% - 20px)',
                            maxWidth: '500px',
                            fontSize: '20px',
                        }}>
                        至急、ご利用料金をお支払いください。
                    </div>
                    <br />
                    <Button
                        theme="error"
                        onClick={() => {
                            navigate(profile ? '/change-profile' : '/login');
                            setOpenModalErrorCard(false);
                        }}
                        title="お支払い情報変更はこちらより"
                    />
                </div>
            </Modal>
            <div
                className={`sidebar-menu sidebar-menu-${
                    openMenu === null ? 'default' : openMenu === undefined ? 'none' : openMenu ? 'open' : 'close'
                }`}>
                <div className="logo">
                    <img onClick={() => {
                        window.location = "https://insta-style.club"
                    }} alt="画像" src={LogoImg} />
                </div>
                <div className="list">
                    <div
                        onClick={() => navigate(`/`)}
                        className={`user hover ${(route.pathname === '/' || isMyProfile()) && 'checked'}`}>
                        <div className="avatar">
                            <img alt="画像" src={profile && profile.avatar} />
                        </div>
                        {profile && profile.full_name}
                    </div>
                    <div className="line"></div>
                    {menuListBar?.map((item, index) => {
                        if (item?.type === menuType.MAIN) {
                            return (
                                <div key={index} className="menu-title">
                                    {item.title}
                                </div>
                            );
                        }
                        if (item?.type === menuType.ITEM) {
                            return (
                                <Link
                                    onClick={e => onCheckAccess(e, item)}
                                    key={index}
                                    style={{ textDecoration: 'none' }}
                                    target={item.target ?? null}
                                    to={item?.to}>
                                    <div
                                        className={`menu-item ${item.noHover ? 'no-hover' : 'hover'} ${
                                            (route.pathname === item.to || profileType(item) === 'other') && 'checked'
                                        } ${item.className || ""}`}>
                                        {item.icon}
                                        {item.title}
                                    </div>
                                </Link>
                            );
                        }
                        return <React.Fragment key={index}></React.Fragment>;
                    })}
                </div>
            </div>
            <div style={{ minWidth: `${newWidth > 792 ? 792 : newWidth}px` }} className={`sidebar-container sidebar-container-${
                    openMenu === null ? 'default' : openMenu === undefined ? 'none' : openMenu ? 'open' : 'close'
                }`}>
                <div className={`sidebar-container-menu sidebar-container-menu-${
                    openMenu === null ? 'default' : openMenu === undefined ? 'none' : openMenu ? 'open' : 'close'
                }`}>
                    <div className="menu">
                        <div
                            className="menu-icon cssNoCopy"
                            onClick={() => setOpenMenu(openMenu === undefined || openMenu === false ? true : false)}>
                            {openMenu ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                        </div>
                        メニュー
                    </div>
                    <div className="login">
                        {/* <MdOutlineNotificationsNone style={{ fontSize: '25px', color: 'grey' }} /> */}

                        <div className="avatar">
                            <img alt="画像" src={profile && profile.avatar} />
                            <Popover
                                trigger="click"
                                placement="bottomLeft"
                                title={''}
                                content={
                                    <React.Fragment>
                                        <div
                                            className="login-avatar-hover-item"
                                            onClick={() => {
                                                setProfile();
                                                setAccount();
                                                setlistProfileCommunity();
                                                setlistTodayNews();
                                                localStorage.clear();
                                                sessionStorage.clear();
                                                navigate('/login');
                                            }}>
                                            ログアウト
                                        </div>
                                    </React.Fragment>
                                }>
                                {profile && profile.full_name}
                            </Popover>
                        </div>
                    </div>
                </div>
                <div onClick={onHandleClearOpen} className="sidebar-container-content">
                    <div className="sidebar-container-content-top">{props?.children}</div>
                    <div className="sidebar-container-footer">
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LayoutSideBar;

export const IconWarning = () => {
    return (
        <div className="layout-sidebar-icon-warning">
            <svg
                viewBox="64 64 896 896"
                focusable="false"
                dataIcon="warning"
                width="1em"
                height="1em"
                fill="currentColor"
                ariaHidden="true">
                <path d="M955.7 856l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zM480 416c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v184c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V416zm32 352a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"></path>
            </svg>
        </div>
    );
};
