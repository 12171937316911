import React from 'react';
import { Checkbox, Form, Input, Notification, PageTitle, Radio, Select } from '../../../component';
import { defaultErrorSetting, typeTabSetting } from '../../../constants/setting';
import { notification } from 'antd';
import useWidth from '../../../hooks/useWidth';
import reState from '../../../hooks/ReState';

const SettingOne = ({ setTabSetting, dataUpdate, listDataJob, listDataAddress, setDataUpdate, listDataInterest }) => {
    //hooks
    const { width } = useWidth();
    const { stripeErrorPayment } = React.useContext(reState);
    // Validate
    const [dataError, setDataError] = React.useState(defaultErrorSetting);
    const [messageApi, contextHolder] = notification.useNotification();
    //
    const onClearError = () => {
        setDataError(defaultErrorSetting);
    };

    const handleChangeRadioSex = e => {
        setDataUpdate({
            ...dataUpdate,
            sex: Number(e.target.value),
        });
        onClearError();
    };

    const handleChangeSelectAddress = e => {
        setDataUpdate({
            ...dataUpdate,
            address_stt: e ?? null,
            addressStt: e ?? null,
        });
        onClearError();
    };

    const handleChangeSelectJob = e => {
        setDataUpdate({
            ...dataUpdate,
            job_stt: e ?? null,
            jobStt: e ?? null,
        });
        onClearError();
    };

    const handleChangeCheckbox = e => {
        let oldListIns = dataUpdate.interest ? dataUpdate.interest : [];
        let newItem = {
            ...e,
            checked: !e.checked,
            interest_stt: e.stt,
            stt: null,
        };
        if (oldListIns.filter(value => value.interest_stt === newItem.interest_stt).length === 0) {
            oldListIns.push(newItem);
        } else {
            oldListIns = oldListIns ? oldListIns.filter(value => value.interest_stt !== newItem.interest_stt) : [];
        }
        setDataUpdate({
            ...dataUpdate,
            interest: oldListIns,
            interests: oldListIns.map(value => value.interest_stt),
        });
        onClearError();
    };

    return (
        <React.Fragment>
            {contextHolder}
            <PageTitle
                title="プロフィール設定 1/3"
                // breadcrumb={[
                //     {
                //         title: 'ボーム',
                //     },
                //     {
                //         title: 'マイプロフィール設定_1/3',
                //     },
                // ]}
            />
            {stripeErrorPayment && (
                <>
                    <br />
                    <Notification
                        title="決済エラーの場合"
                        content={
                            <React.Fragment>
                                決済エラーの場合、ご利用可能なお支払い情報へ更新されますと自動的に決済が再開されます。
                            </React.Fragment>
                        }
                        line="default"
                        type="red"
                    />
                </>
            )}
            <br />
            <Form
                buttonEvent={e => {
                    if (!e.fullName || e.fullName.trim().length === 0) {
                        setDataError({
                            ...dataError,
                            fullName: '名前を空白にすることはできません',
                        });
                        messageApi.error({
                            message: '名前を空白にすることはできません',
                            placement: 'bottomRight',
                        });
                    } else if (!e.nickname || e.nickname.trim().length === 0) {
                        setDataError({
                            ...dataError,
                            nickname: 'ニックネームを空白にすることはできません',
                        });
                        messageApi.error({
                            message: 'ニックネームを空白にすることはできません',
                            placement: 'bottomRight',
                        });
                    } else if (!dataUpdate.sex && String(dataUpdate.sex) !== '0' && String(dataUpdate.sex) !== '1') {
                        setDataError({
                            ...dataError,
                            sex: 'あなたの性別を選択してください',
                        });
                        messageApi.error({
                            message: 'あなたの性別を選択してください',
                            placement: 'bottomRight',
                        });
                    } else if (!e.birthday || e.birthday.trim().length === 0) {
                        setDataError({
                            ...dataError,
                            birthday: '生年月日を入力してください',
                        });
                        messageApi.error({
                            message: '生年月日を入力してください',
                            placement: 'bottomRight',
                        });
                    } else if (String(new Date(e.birthday)) === 'Invalid Date') {
                        setDataError({
                            ...dataError,
                            birthday: '生年月日の形式が正しくありません YYYY/MM/DD',
                        });
                        messageApi.error({
                            message: '生年月日の形式が正しくありません YYYY/MM/DD',
                            placement: 'bottomRight',
                        });
                    } else if (!dataUpdate.address_stt) {
                        setDataError({
                            ...dataError,
                            address: '住所を選択してください',
                        });
                        messageApi.error({
                            message: '住所を選択してください',
                            placement: 'bottomRight',
                        });
                    } 
                    // else if (!dataUpdate.job_stt) {
                    //     setDataError({
                    //         ...dataError,
                    //         job: 'お仕事を選んでください',
                    //     });
                    //     messageApi.error({
                    //         message: 'お仕事を選んでください',
                    //         placement: 'bottomRight',
                    //     });
                    // } 
                    else if (!dataUpdate.interest || dataUpdate.interest.length === 0) {
                        setDataError({
                            ...dataError,
                            interest: '少なくとも 1 つの興味を選択してください',
                        });
                        messageApi.error({
                            message: '少なくとも 1 つの興味を選択してください',
                            placement: 'bottomRight',
                        });
                    } else {
                        setDataUpdate({
                            ...dataUpdate,
                            ...e,
                        });
                        setTabSetting(typeTabSetting.TWO);
                    }
                }}
                content={
                    <React.Fragment>
                        <br />
                        <Input
                            title="お名前"
                            subText="非公開"
                            tick="必須"
                            noteText="氏名は公開されません。"
                            defaultValue={dataUpdate.full_name}
                            name="fullName"
                            color="green"
                            error={dataError.fullName}
                            errorText={dataError.fullName}
                            onChange={onClearError}
                            maxLength={40}
                            placeholder="大谷翔平"
                        />
                        <br />
                        <Input
                            title="表示名"
                            tick="必須"
                            defaultValue={dataUpdate.nickname}
                            name="nickname"
                            error={dataError.nickname}
                            errorText={dataError.nickname}
                            onChange={onClearError}
                            maxLength={28}
                            placeholder="ショーヘイ"
                        />
                        <br />
                        <Radio
                            title="性別"
                            tick="必須"
                            value={dataUpdate.sex || String(dataUpdate.sex) === '0' ? Number(dataUpdate.sex) : null}
                            name="sex"
                            error={dataError.sex}
                            errorText={dataError.sex}
                            options={[
                                { title: '男性', value: 0 },
                                { title: '女性', value: 1 },
                            ]}
                            onChange={handleChangeRadioSex}
                        />
                        <br />
                        <Input
                            title="生年月日"
                            subText="非公開"
                            placeholder="2000/01/01"
                            tick="必須"
                            noteText="年代のみ表示となり、生年月日は公開されません。"
                            defaultValue={dataUpdate.birthday ? dataUpdate.birthday.replaceAll('-', '/') : ''}
                            name="birthday"
                            error={dataError.birthday}
                            errorText={dataError.birthday}
                            onChange={onClearError}
                        />
                        <br />
                        <Select
                            title="地域"
                            tick="必須"
                            defaultValue={dataUpdate.address_stt}
                            name="address_stt"
                            options={
                                listDataAddress && listDataAddress.length
                                    ? listDataAddress.map(value => {
                                          return {
                                              ...value,
                                              label: value.name,
                                              value: value.stt,
                                          };
                                      })
                                    : []
                            }
                            onChange={handleChangeSelectAddress}
                            error={dataError.address}
                            errorText={dataError.address}
                            showSearch
                        />
                        {/* <br />
                        <Select
                            title="はたらきかた"
                            tick="必須"
                            defaultValue={dataUpdate.job_stt}
                            name="job_stt"
                            options={
                                listDataJob && listDataJob.length
                                    ? listDataJob.map(value => {
                                          return {
                                              ...value,
                                              label: value.job,
                                              value: value.stt,
                                          };
                                      })
                                    : []
                            }
                            onChange={handleChangeSelectJob}
                            error={dataError.job}
                            errorText={dataError.job}
                            showSearch
                        /> */}
                        <br />
                        <Checkbox
                            title="好きなジャンル"
                            tick="必須"
                            options={
                                listDataInterest && listDataInterest.length
                                    ? listDataInterest.map(value => {
                                          return {
                                              ...value,
                                              label: value.name,
                                              value: value.stt,
                                              checked:
                                                  dataUpdate.interest && dataUpdate.interest.length
                                                      ? dataUpdate.interest.filter(
                                                            item => Number(item.interest_stt) === Number(value.stt),
                                                        ).length > 0
                                                          ? true
                                                          : false
                                                      : false,
                                          };
                                      })
                                    : []
                            }
                            span={width < 792 ? 12 : 8}
                            onChange={handleChangeCheckbox}
                            noteText="複数選択可能"
                            error={dataError.interest}
                            errorText={dataError.interest}
                        />
                    </React.Fragment>
                }
                title="基本情報設定"
                buttonTitle="次へ"
            />
        </React.Fragment>
    );
};

export default SettingOne;
