import client from '../../apollo-client';
import { gql } from '@apollo/client';


type TypeProps = {
    updateCardId: number;
    stripe: String;
    last4: String;
    brand: String;
    expMonth: number;
    expYear: number;
    authenticate_success?: Boolean;
};

export const updateCardPaymentAPI = async ({ updateCardId, stripe, last4, brand, expMonth, expYear, authenticate_success }: TypeProps) => {
    const data: any = await client.mutate({
        mutation: gql`
            mutation UpdateCard(
                $updateCardId: ID!
                $stripe: String
                $last4: String
                $brand: String
                $expMonth: Int
                $expYear: Int
                $authenticate_success: Boolean
            ) {
                updateCard(
                    id: $updateCardId
                    stripe: $stripe
                    last4: $last4
                    brand: $brand
                    exp_month: $expMonth
                    exp_year: $expYear
                    authenticate_success: $authenticate_success
                ) {
                    resul
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                    client_secret
                    need_3d_authen
                }
            }
        `,
        variables: {
            updateCardId,
            stripe,
            last4,
            brand,
            expMonth,
            expYear,
            authenticate_success,
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.updateCard ?? {};
};
