// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.community-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

@media only screen and (max-width: 800px) {
  .community-page .profile-item {
    width: 100% !important;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .community-page .profile-item {
    width: calc(50% - 10px) !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1700px) {
  .community-page .profile-item {
    width: calc(33% - 20px) !important;
  }
}
@media only screen and (min-width: 1700px) {
  .community-page .profile-item {
    width: calc(33% - 10px) !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/community/CommunityPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,uBAAA;EACA,SAAA;AACJ;;AAEA;EAEQ;IACI,sBAAA;EAAV;AACF;AAGA;EAEQ;IACI,kCAAA;EAFV;AACF;AAKA;EAEQ;IACI,kCAAA;EAJV;AACF;AAOA;EAEQ;IACI,kCAAA;EANV;AACF","sourcesContent":[".community-page{\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    gap: 20px;\n}\n\n@media only screen and (max-width: 800px) {\n    .community-page {\n        .profile-item {\n            width: 100% !important;\n        }\n    }\n}\n@media only screen and (min-width: 800px) and (max-width: 1200px) {\n    .community-page {\n        .profile-item {\n            width: calc(50% - 10px) !important;\n        }\n    }\n}\n@media only screen and (min-width: 1200px) and (max-width: 1700px) {\n    .community-page {\n        .profile-item {\n            width: calc(33% - 20px) !important;\n        }\n    }\n}\n@media only screen and (min-width: 1700px)  {\n    .community-page {\n        .profile-item {\n            width: calc(33% - 10px) !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
