import React from 'react';
import { useLocation } from 'react-router-dom';
import { profileAPI } from '../../services/graphql/profile';
import { Skeleton, Tooltip } from '../../component';
import InfoImg from '../../assets/info.png';
import './ProfilePage.scss';
import NotFound from './NotFound';
import reState from '../../hooks/ReState';
import { listJobAPI } from '../../services/graphql/job';
import { listAddressAPI } from '../../services/graphql/address';
import { listInterestAPI } from '../../services/graphql/interest';
import { logoNetwork } from '../../constants/profile';
import { onOpenSnS } from '../../constants/sns';
import useResponsive from '../../hooks/useResponsive';

const ProfilePage = () => {
    const route = useLocation();
    const [pathname, setPathname] = React.useState('');
    const [profile, setProfile] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const responsive = useResponsive()
    const { listDataJob, setListDataJob, listDataAddress, setListDataAddress, listDataInterest, setListDataInterest } =
        React.useContext(reState);
    React.useEffect(() => {
        setPathname(route.pathname.split('/')[1]);
    }, [route]);

    React.useEffect(() => {
        if (pathname && pathname.length > 0) {
            const callAPI = async () => {
                setLoading(true);
                const result = await profileAPI({
                    profileId: pathname,
                });
                if (result && result.status === '200' && result.resul.length > 0) {
                    setProfile(result.resul[0]);
                    setLoading(false);
                } else {
                    setProfile();
                    setLoading(false);
                }
                await onOpenSnS(
                    {
                        type: 'profile',
                    },
                    {
                        id: Number(pathname),
                    },
                    true,
                );
            };
            callAPI();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, route]);

    React.useEffect(() => {
        const callAPI = async () => {
            if (!listDataJob) {
                const result = await listJobAPI({ keySearch: null });
                if (result && result.status === '200') {
                    setListDataJob(result.resul);
                }
            }
            if (!listDataAddress) {
                const result = await listAddressAPI({ keySearch: null });
                if (result && result.status === '200') {
                    setListDataAddress(result.resul);
                }
            }
            if (!listDataInterest) {
                const result = await listInterestAPI({ keySearch: null });
                if (result && result.status === '200') {
                    setListDataInterest(result.resul);
                }
            }
        };
        callAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <br />
            {loading ? (
                <>
                    <Skeleton style={{ width: '100%', height: '300px' }} />
                    <br />
                    <br />
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <Skeleton style={{ width: '100%', height: '50px' }} />
                        <Skeleton style={{ width: '100%', height: '50px' }} />
                    </div>
                    <br />
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <Skeleton style={{ width: '100%', height: '50px' }} />
                        <Skeleton style={{ width: '100%', height: '50px' }} />
                    </div>
                </>
            ) : !profile ? (
                <NotFound />
            ) : (
                <React.Fragment>
                    <div className="profile-page-profile">
                        <div className="profile-page-profile-user">
                            {/* <div className="profile-page-profile-name">{profile && profile.nickname}</div> */}
                        </div>
                        <div className="profile-page-profile-avatar">
                            <img alt="画像" src={profile.avatar} />
                            <div className="profile-page-profile-username">ID: {profile.id}</div>
                        </div>
                        <div className="profile-page-content-network">
                            {profile
                                ? [
                                      ...(profile.instagram
                                          ? [
                                                {
                                                    type: 'instagram',
                                                    url: process.env.REACT_APP_URL_INSTAGRAM + profile.instagram,
                                                },
                                            ]
                                          : []),
                                      ...(profile.tiktok
                                          ? [
                                                {
                                                    type: 'tik-tok',
                                                    url: process.env.REACT_APP_URL_TIKTOK + profile.tiktok,
                                                },
                                            ]
                                          : []),
                                      ...(profile.twitter
                                          ? [
                                                {
                                                    type: 'twitter',
                                                    url: process.env.REACT_APP_URL_TWITTER + profile.twitter,
                                                },
                                            ]
                                          : []),
                                      ...(profile.youtube
                                          ? [
                                                {
                                                    type: 'youtube',
                                                    url: process.env.REACT_APP_URL_YOUTUBE + profile.youtube,
                                                },
                                            ]
                                          : []),
                                  ]?.map((network, index) => (
                                      <img
                                          onClick={() => onOpenSnS(network, profile)}
                                          style={{ cursor: 'pointer' }}
                                          key={index}
                                          alt="画像"
                                          src={logoNetwork[network.type]}
                                      />
                                  ))
                                : ''}
                        </div>
                        <div className="profile-page-profile-list">
                            {/* <div className="profile-page-profile-list-item">
                                <div>0</div>
                                <div className="profile-page-profile-list-item-title">
                                    ランク
                                    <Tooltip
                                        content="ランクが上がる様々な追加機能が利用可能になります。ランクアップパスを購入するとすぐにランクを上げることが出来ます。"
                                        title="ランク">
                                        <img alt="画像" src={InfoImg} />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="profile-page-profile-list-line"></div>
                            <div className="profile-page-profile-list-item">
                                <div>0</div>
                                <div className="profile-page-profile-list-item-title">
                                    ポイント
                                    <Tooltip content="様々な特典に利用できるポイントです。" title="ポイント">
                                        <img alt="画像" src={InfoImg} />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="profile-page-profile-list-line"></div> */}
                            <div className="profile-page-profile-list-item">
                                <div>{profile.sns_view}</div>
                                <div className="profile-page-profile-list-item-title">
                                    SNS訪問者数
                                    <Tooltip content="他のメンバーからSNSアカウントを閲覧された回数です。(重複は除きます)" title="SNS訪問者数">
                                        <img alt="画像" src={InfoImg} />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="profile-page-profile-list-line"></div>
                            <div className="profile-page-profile-list-item">
                                <div>{profile.sns_action}</div>
                                <div className="profile-page-profile-list-item-title">
                                    本日のアクション数
                                    <Tooltip
                                        type={responsive ? "top" : 'right'}
                                        content="他のメンバーのプロフィールまたはSNSアカウントを閲覧した回数です。(重複は除きます)"
                                        title="本日のアクション数">
                                        <img alt="画像" src={InfoImg} />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="profile-page-info">
                        <div className="profile-page-info-top"></div>
                        <div className="profile-page-info-content">
                            <div className="profile-page-info-content-item">
                                <div className="pp-info-ci-content">
                                    <div className="pp-info-ci-content-label">ニックネーム</div>
                                    <div className="pp-info-ci-content-text">{profile.nickname}</div>
                                </div>
                                <div className="profile-page-info-content-line-2"></div>
                                <div className="pp-info-ci-content">
                                    <div className="pp-info-ci-content-label">地域</div>
                                    <div className="pp-info-ci-content-text">
                                        {listDataAddress && listDataAddress.length && profile && profile.address_stt
                                            ? listDataAddress.filter(value => value.stt === profile.address_stt)[0]
                                                  ?.name
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="profile-page-info-content-line"></div>
                            <div className="profile-page-info-content-item">
                                <div className="pp-info-ci-content">
                                    <div className="pp-info-ci-content-label">性別</div>
                                    <div className="pp-info-ci-content-text">
                                        {profile.sex ? '女性' : profile.sex === null ? '' : '男性'}
                                    </div>
                                </div>
                                <div className="profile-page-info-content-line-2"></div>
                                <div className="pp-info-ci-content">
                                    <div className="pp-info-ci-content-label">年齢</div>
                                    <div className="pp-info-ci-content-text">
                                        {profile.year_old ? profile.year_old + '歳' : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="profile-page-info-content-line"></div>
                            <div className="profile-page-info-content-item">
                                <div className="pp-info-ci-content">
                                    <div className="pp-info-ci-content-label">お仕事</div>
                                    <div className="pp-info-ci-content-text">
                                        {listDataJob && listDataJob.length && profile && profile.job_stt
                                            ? listDataJob.filter(value => value.stt === profile.job_stt)[0]?.job
                                            : ''}
                                    </div>
                                </div>
                                <div className="profile-page-info-content-line-2"></div>
                                <div className="pp-info-ci-content">
                                    <div className="pp-info-ci-content-label">得意ジャンル</div>
                                    <div className="pp-info-ci-content-text">
                                        {profile && profile.interest && profile.interest.length
                                            ? profile.interest.map(value => value.name).join(', ')
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="profile-page-info-content-line"></div>
                            <div className="profile-page-info-content-item">
                                <div className="pp-info-ci-content">
                                    <div className="pp-info-ci-content-label">自己紹介</div>
                                    <div className="pp-info-ci-content-text">{profile.introduce_yourself}</div>
                                </div>
                                {/* <div className="profile-page-info-content-line-2"></div>
                                <div className="pp-info-ci-content">
                                    <div className="pp-info-ci-content-label">ID</div>
                                    <div className="pp-info-ci-content-text"></div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default ProfilePage;
