import axios from 'axios';

export const listPackage = async () => {
    const result = await axios.get(process.env.REACT_APP_GRAPHQL_URL + 'stripe-product');

    if (result && result.data && result.data.resul && result.data.status === '200') {
        return result.data.resul.filter((value) => value.metadata?.option !== 'true')
    }
    return [];
};

export const listOptions = async () => {
  const result = await axios.get(process.env.REACT_APP_GRAPHQL_URL + 'stripe-product');

  if (result && result.data && result.data.resul && result.data.status === '200') {
      return result.data.resul.filter((value) => value.metadata?.option === 'true')
  }
  return [];
};

export const createTokenStripe = async param => {
    const result = await axios.post(process.env.REACT_APP_GRAPHQL_URL + 'create-token', param);

    if (result && result.data && result.data.status === '200') {
        return result.data.resul || {};
    }
    if (result && result.data) {
        return {status: result.data.status, message: result.data.mess};
    }
    return {};
};

export const retrieveProduct = async id => {
    const result = await axios.get(process.env.REACT_APP_GRAPHQL_URL + 'retrieve-product/' + id);

    if (result && result.data && result.data.status === '200') {
        return result.data.resul || {};
    }
    return {};
};

export const retrieveSub = async id => {
    const result = await axios.get(process.env.REACT_APP_GRAPHQL_URL + 'retrieve-subscriptions/' + id);

    if (result && result.data && result.data.status === '200') {
        return result.data.resul || {};
    }
    return {};
};
