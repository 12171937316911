import client from '../../apollo-client';
import { gql } from '@apollo/client';

type TypeProps = {
    account?: string;
    token?: string;
    password?: string;
};

export const confirmEmailForgotPasswordAPI = async ({ account = '' }: TypeProps) => {
    const data: any = await client.mutate({
        mutation: gql`
            mutation ConfirmEmailForgotPassword($account: String) {
                confirmEmailForgotPassword(account: $account) {
                    resul
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            account,
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.confirmEmailForgotPassword ?? {};
};

export const checkTokenForgotPasswordAPI = async ({ token = '' }: TypeProps) => {
    const data: any = await client.mutate({
        mutation: gql`
            mutation CheckTokenForgotPassword($token: String) {
                checkTokenForgotPassword(token: $token) {
                    resul
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            token,
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.checkTokenForgotPassword ?? {};
};

export const changeForgotPasswordAPI = async ({ token = '', password = '' }: TypeProps) => {
    const data: any = await client.mutate({
        mutation: gql`
            mutation ChangeForgotPassword($token: String, $password: String) {
                changeForgotPassword(token: $token, password: $password) {
                    resul
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            token,
            password,
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.changeForgotPassword ?? {};
};
