import React, { useState } from 'react';
import { useSettingState } from './hooks';
import { typeTabSetting } from '../../constants/setting';
import SettingOne from './tab/SettingOne';
import SettingTwo from './tab/SettingTwo';
import SettingThree from './tab/SettingThree';
import reState from '../../hooks/ReState';
import { listJobAPI } from '../../services/graphql/job';
import { listAddressAPI } from '../../services/graphql/address';
import { listInterestAPI } from '../../services/graphql/interest';

const SettingsPage = () => {
    const {
        profile,
        listDataJob,
        setListDataJob,
        listDataAddress,
        setListDataAddress,
        listDataInterest,
        setListDataInterest,
    } = React.useContext(reState);
    const { tabSetting, setTabSetting } = useSettingState();
    const [dataUpdate, setDataUpdate] = useState();

    React.useEffect(() => {
        setDataUpdate(profile);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);

    React.useEffect(() => {
        const callAPI = async () => {
            if (!listDataJob) {
                const result = await listJobAPI({ keySearch: null });
                if (result && result.status === '200') {
                    setListDataJob(result.resul);
                }
            }
            if (!listDataAddress) {
                const result = await listAddressAPI({ keySearch: null });
                if (result && result.status === '200') {
                    setListDataAddress(result.resul);
                }
            }
            if (!listDataInterest) {
                const result = await listInterestAPI({ keySearch: null });
                if (result && result.status === '200') {
                    setListDataInterest(result.resul);
                }
            }
        };
        callAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {dataUpdate && (
                <React.Fragment>
                    {typeTabSetting.ONE === tabSetting && (
                        <SettingOne
                            {...{
                                setTabSetting,
                                dataUpdate,
                                setDataUpdate,
                                listDataJob,
                                listDataAddress,
                                listDataInterest,
                            }}
                        />
                    )}
                    {typeTabSetting.TWO === tabSetting && (
                        <SettingTwo {...{ setTabSetting, dataUpdate, setDataUpdate }} />
                    )}
                    {typeTabSetting.THREE === tabSetting && (
                        <SettingThree {...{ setTabSetting, dataUpdate, setDataUpdate }} />
                    )}
                </React.Fragment>
            )}
        </>
    );
};

export default SettingsPage;
