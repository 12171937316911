import { createContext } from 'react';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { profileAPI } from '../services/graphql/profile';
import { retrieveProduct, retrieveSub } from '../services/listPack/listPack';
const reState = createContext();

export function ContextProvider({ children }) {
    const [profile, setProfile] = useState();
    const [account, setAccount] = useState();
    const [stripeNameSubscription, setStripeNameSubscription] = useState();
    const [stripePriceSubscription, setStripePriceSubscription] = useState();
    const [stripeErrorPayment, setStripeErrorPayment] = useState(false);
    const [listProfileCommunity, setlistProfileCommunity] = React.useState();
    const [listTodayNews, setlistTodayNews] = React.useState();
    const history = useNavigate();
    const route = useLocation();
    const noReturnRoutes = ['/account/accept-remove', '/account/remove', '/account/success', '/account/cancel-back', '/account/accept-success', '/forgot-password', '/register/success',];

    React.useEffect(() => {
        const token = localStorage.getItem('__ft');
        const refreshToken = sessionStorage.getItem('__at');
        const id = localStorage.getItem('__ip');
        if ((token || refreshToken) && id) {
            setAccount({
                at: token ?? '',
                ft: refreshToken ?? '',
                id: id,
            });
        } else {
            localStorage.clear();
            sessionStorage.clear();
            if (!noReturnRoutes.filter(value => value === route.pathname).length) {
                history(route.pathname === '/register' ? '/register' + route.search : '/login', {
                    state: route.search?.indexOf('?tab=sign') > -1 ? 'sign' : null,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (account && !profile) {
            if (localStorage.getItem('__ip')) {
                const callAPIProfile = async () => {
                    const result = await profileAPI({ profileId: account.id });
                    if (result && result.status === '200' && result.resul[0]?.status === "normal") {
                        setProfile(result.resul[0]);
                        if(result.resul[0]?.stripeStatusError){
                            setStripeErrorPayment(true)
                        }
                    } else {
                        localStorage.clear();
                        sessionStorage.clear();
                        history(route.pathname === '/register' ? '/register' : '/login');
                    }
                };
                callAPIProfile();
            } else {
                window.location.reload();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, route]);

    React.useEffect(() => {
        if (profile && !stripeNameSubscription) {
            const callAPI = async () => {
                const subscription = await retrieveSub(profile.payment.stripe);
                if (subscription && subscription.plan) {
                    const product = await retrieveProduct(subscription.plan.product);
                    if (product) {
                        setStripeNameSubscription(product.name ?? '');
                        setStripePriceSubscription(product?.default_price ?? '')
                    }
                    if (subscription.status === 'past_due') {
                        setStripeErrorPayment(true);
                    }
                }
            };
            callAPI();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);

    const [listDataJob, setListDataJob] = useState();
    const [listDataAddress, setListDataAddress] = useState();
    const [listDataInterest, setListDataInterest] = useState();

    return (
        <>
            <reState.Provider
                value={{
                    profile,
                    setProfile,
                    account,
                    setAccount,
                    listDataJob,
                    setListDataJob,
                    listDataAddress,
                    setListDataAddress,
                    listDataInterest,
                    setListDataInterest,
                    stripeNameSubscription,
                    setStripeNameSubscription,
                    stripeErrorPayment,
                    setStripeErrorPayment,
                    listProfileCommunity,
                    setlistProfileCommunity,
                    listTodayNews,
                    setlistTodayNews,
                    stripePriceSubscription,
                    setStripePriceSubscription
                }}>
                {children}
            </reState.Provider>
        </>
    );
}

export default reState;
