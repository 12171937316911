import StripeErrorTypes from "./stripeErrorTypes.js";

const defineMessage = (error) => {
  if (error) {
    if (error.code) {
      const errorMessage = StripeErrorTypes[error.code];
      if (errorMessage) {
        return `${errorMessage}(${error.code})`;
      } else if (error.decline_code && StripeErrorTypes[error.decline_code]) {
        return `${StripeErrorTypes[error.decline_code]}(${error.decline_code})`;
      }
      return `決済エラー:クレジットカード認証エラー(${error.code})`;
    }
    return 'エラーが発生したため、再度お試しください。';
  }
  return null;
}

export default defineMessage;
