import React from 'react';
import './style.scss';
import { FormNoteText, FormSubText, FormTick, FormTitle } from '..';
import { Checkbox, Col, Row } from 'antd';

type TypeProps = {
    title?: string;
    subText?: string;
    tick?: string;
    noteText?: string;
    onChange?: (arg?: any) => any;
    options?: {
        value: any;
        label: any;
        disabled?: boolean;
        [key: string]: any;
    }[];
    disabled?: boolean;
    span?: number
};

const CheckBoxForm = ({
    title = '',
    tick = '',
    subText = '',
    noteText = '',
    onChange = () => {},
    options,
    span= 24
}: TypeProps) => {
    return (
        <React.Fragment>
            {title && (
                <div style={{ marginBottom: '10px' }}>
                    <FormTitle>{title}</FormTitle>
                    {subText && <FormSubText>{subText}</FormSubText>}
                    {tick && <FormTick>{tick}</FormTick>}
                </div>
            )}
            <div className="radio">
                <Row>
                    {options &&
                        options.map((option: any, key) => (
                            <Col key={key} span={span}>
                                <Checkbox
                                    checked={option.checked}
                                    key={key}
                                    disabled={option.disabled}
                                    onChange={() => onChange(options[key])}>
                                    <FormTitle>{option.label}</FormTitle>
                                </Checkbox>
                            </Col>
                        ))}
                </Row>
            </div>
            {noteText && (
                <div>
                    <FormNoteText>{noteText}</FormNoteText>
                </div>
            )}
        </React.Fragment>
    );
};

export default CheckBoxForm;
