import client from '../../apollo-client';
import { gql } from '@apollo/client';

type TypeProps = {
    plan?: string;
    id?: number;
};

export const changePlanAPI = async ({ id, plan }: TypeProps) => {
    const data: any = await client.mutate({
        mutation: gql`
            mutation ChangePlan($plan: String!, $id: ID!) {
                changePlan(plan: $plan, id: $id) {
                    resul
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            id,
            plan,
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.changePlan ?? {};
};

export const createOptionAPI = async ({ id, plan }: TypeProps) => {
    const data: any = await client.mutate({
        mutation: gql`
            mutation CreateOption($plan: String!, $id: ID!) {
                createOption(plan: $plan, id: $id) {
                    resul
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            id,
            plan,
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.createOption ?? {};
};
