import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import './RegisterPage.scss';
import SignPage from './SignPage';
import LoginPage from './LoginPage';
import SignSuccess from './SignSuccess';
import reState from '../../hooks/ReState';
// import CancelPage from './CancelPage';

const RegisterPage = () => {
    const route = useLocation();
    const { account } = React.useContext(reState);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (account) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, route]);
    return (
        <React.Fragment>
            <div className="register-page-logo">
                <img alt="画像" src={Logo} />
            </div>
            <div className="register-page">
                {!account ? (
                    <>
                        {route.pathname === '/login' && !route.state ? <LoginPage /> : ''}{' '}
                        {route.pathname === '/register' || route.state === 'sign' ? <SignPage /> : ''}
                        {route.pathname === '/register/success' && <SignSuccess />}
                    </>
                ) : (
                    ''
                )}
            </div>
            <br/>
        </React.Fragment>
    );
};

export default RegisterPage;
