import client from '../../apollo-client';
import { gql } from '@apollo/client';
 

type TypeProps = {
    profileId?: number;
    name?: string;
    size?: number;
    page?: number;
    nickname?: String;
    sex?: number;
    address?: number;
    story?: string;
    interest?: number;
    job?: number;
    yearStart?: number;
    yearEnd?: number;
    username?: String;
    year_old?: String;
    updateProfileId?: String;
    fullName?: String;
    email?: String;
    packDataStt?: String;
    birthday?: String;
    introduceYourself?: String;
    instagram?: String;
    tiktok?: String;
    twitter?: String;
    youtube?: String;
    addressStt?: String;
    avatar?: String;
    jobStt?: String;
    interests?: number[];
    tokenToday?: String;
    random?: Boolean
    up?:Boolean
};

export const profileAPI = async ({
    address,
    interest,
    job,
    name,
    nickname,
    page,
    profileId,
    sex,
    size,
    story,
    username,
    yearEnd,
    yearStart,
    interests,
    tokenToday,
    random,
    up
}: TypeProps) => {
    const data: any = await client.mutate({
        mutation: gql`
            mutation Profile(
                $profileId: ID
                $name: String
                $size: Int
                $page: Int
                $nickname: String
                $sex: Int
                $address: Int
                $story: String
                $interest: Int
                $job: Int
                $yearStart: Int
                $yearEnd: Int
                $username: String
                $interests: [Int]
                $random: Boolean
                $tokenToday: String
                $up: Boolean
            ) {
                profile(
                    id: $profileId
                    name: $name
                    size: $size
                    page: $page
                    nickname: $nickname
                    sex: $sex
                    address: $address
                    story: $story
                    interest: $interest
                    job: $job
                    yearStart: $yearStart
                    yearEnd: $yearEnd
                    username: $username
                    interests: $interests
                    tokenToday: $tokenToday
                    random: $random
                    up: $up
                ) {
                    resul {
                        id
                        username
                        full_name
                        create_time
                        status
                        email
                        pack_data_stt
                        nickname
                        sex
                        year_old
                        birthday
                        introduce_yourself
                        instagram
                        tiktok
                        twitter
                        youtube
                        job
                        address
                        address_stt
                        job_stt
                        avatar
                        stripeStatusError
                        payment {
                            last4
                            expMonth
                            expYear
                            brand
                            stripe
                            stt
                        }
                        sns_action
                        sns_view
                        systemNotifications {
                            stt
                            type
                            title
                            content
                        }
                        notifications {
                            stt
                            type
                            title
                            content
                        }
                        interest {
                            stt
                            interest_stt
                            name
                            note
                        }
                    }
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            address,
            interest,
            job,
            name,
            nickname,
            page,
            profileId,
            sex,
            size,
            story,
            username,
            yearEnd,
            yearStart,
            interests,
            tokenToday,
            random,
            up
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.profile ?? {};
};

export const updateProfileAPI = async ({
    updateProfileId,
    username,
    fullName,
    email,
    packDataStt,
    nickname,
    sex,
    birthday,
    introduceYourself,
    instagram,
    tiktok,
    youtube,
    addressStt,
    avatar,
    jobStt,
    interests,
    twitter,
}: TypeProps) => {
    const data: any = await client.mutate({
        mutation: gql`
            mutation UpdateProfile(
                $updateProfileId: ID!
                $username: String
                $fullName: String
                $email: String
                $packDataStt: String
                $nickname: String
                $birthday: String
                $introduceYourself: String
                $instagram: String
                $tiktok: String
                $twitter: String
                $youtube: String
                $addressStt: Int
                $avatar: String
                $jobStt: Int
                $interests: [Int]
                $sex: Int
            ) {
                updateProfile(
                    id: $updateProfileId
                    username: $username
                    full_name: $fullName
                    email: $email
                    pack_data_stt: $packDataStt
                    nickname: $nickname
                    birthday: $birthday
                    introduce_yourself: $introduceYourself
                    instagram: $instagram
                    tiktok: $tiktok
                    twitter: $twitter
                    youtube: $youtube
                    address_stt: $addressStt
                    avatar: $avatar
                    job_stt: $jobStt
                    interest: $interests
                    sex: $sex
                ) {
                    resul {
                        id
                        username
                        full_name
                        create_time
                        status
                        email
                        pack_data_stt
                        nickname
                        sex
                        birthday
                        introduce_yourself
                        instagram
                        tiktok
                        twitter
                        youtube
                        job
                        address
                        address_stt
                        job_stt
                        avatar
                        interest {
                            stt
                            interest_stt
                            name
                            note
                        }
                    }
                    status
                    mess
                    size
                    total
                    page
                    html
                    timeRequest
                    token
                    refreshToken
                }
            }
        `,
        variables: {
            updateProfileId,
            username,
            fullName,
            email,
            packDataStt,
            nickname,
            sex,
            birthday,
            introduceYourself,
            instagram,
            tiktok,
            youtube,
            addressStt,
            avatar,
            jobStt,
            interests,
            twitter,
        },
        context: {
            headers: {
                Authorization: `${
                    localStorage.getItem('__ft') ? localStorage.getItem('__ft') : sessionStorage.getItem('__at')
                }`,
            },
        },
    });

    return data?.data?.updateProfile ?? {};
};
