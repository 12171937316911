import React from 'react';
import { useNavigate } from 'react-router-dom';
// import LoginSuccessImg from '../../assets/login-success.png';
import { Button } from '../../component';
// import reState from '../../hooks/ReState';

const SignSuccess = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
        document.body.style.overflowY = 'auto';
        document.body.style.paddingBottom = '10px';
        // eslint-disable-next-line
    }, []);
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                fontFamily: 'ヒラのノギ',
                fontWeight: '500',
            }}>
            <div style={{ padding: '0px 10px', fontSize: '15px' }}>
                {/* <img style={{ width: '100%' }} src={LoginSuccessImg} alt="login success" /> */}
                
                <span style={{ fontSize: '20px', fontWeight: '600' }}>ご登録ありがとうございます。</span>
                <br />
                <br />
                マイページログイン情報を
                <br />
                ご登録メールアドレスにお送りしております。
                <br />
                失くさないよう大切に保管してください。
                <br />
                <br />
                {/* <span style={{ fontWeight: '600' }}>【ログイン情報】</span>
                <br />
                <div style={{ width: '100%', textAlign: 'left', marginTop: '10px' }}>
                    ログインID：{profile?.email || ''} <br />
                    パスワード：※指定のパスワード
                </div>
                <br />
                <span style={{ fontWeight: '600' }}>【ご登録内容をご確認ください】</span>
                <br />
                <div style={{ width: '100%', textAlign: 'left', marginTop: '10px' }}>
                    お名前：{profile?.full_name || ''} <br />
                    メールアドレス：{profile?.email || ''} <br />
                    プラン：{stripeNameSubscription || ''} <br />
                </div> */}
            </div>
            <br />
            <Button onClick={() => navigate('/login')} title="ログインページに戻る" />
        </div>
    );
};

export default SignSuccess;
