import React, { useState } from 'react';
import Logo from '../../assets/logo.png';
import './CancelAccount.scss';
import { Button, Input, Notification } from '../../component';
import { FaEnvelope } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { sendEmailRemoveAccount } from '../../services/graphql/account';
import { REGEX_EMAIL } from '../../constants/regex';
import reState from '../../hooks/ReState';

const CancelAccount = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { profile } = React.useContext(reState);

    React.useEffect(() => {
        document.body.style.overflowY = 'auto';
        document.body.style.paddingBottom = '70px';
        // eslint-disable-next-line
    }, []);

    const onSubmit = async () => {
        if (!email || email.trim().length === 0) {
            setErrorText('メールアドレスを入力してください');
        } else if (!REGEX_EMAIL.test(email.trim())) {
            setErrorText('メールの形式が正しくありません');
        } else {
            setLoading(true);
            const result = await sendEmailRemoveAccount({ email });
            if (result && result.status === '200') {
                navigate('/account/success', {
                    state: 'success',
                });
            } else if (result.status === 'ERROR_1015') {
                navigate('/account/cancel-back');
            } else {
                setError(result?.mess);
            }
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <br />
            <br />
            <div className="cancel-account-page-logo">
                <img alt="画像" src={Logo} />
            </div>
            <div className="cancel-account-page">
                {error && (
                    <>
                        <Notification type="red" titleString={error} theme="error" line="default" />
                        <br />
                    </>
                )}
                <div>
                解約手続きのご案内をお送りいたします。<br/>
ご登録のメールアドレスをご入力ください。
                </div>
                <br />

                <Input
                    onChange={e => {
                        setEmail(e.target.value);
                        setError(false);
                        setErrorText('');
                    }}
                    icon={<FaEnvelope />}
                    placeholder="ご登録のメールアドレス"
                    error={errorText}
                    errorText={errorText}
                />
                <br />
                <Button disabled={loading} onClick={onSubmit} title="解約申請" style={{ width: '100%' }} />
                <br />
                <Link
                    style={{
                        color: 'rgb(255 150 206)',
                        textAlign: 'center',
                        textDecoration: 'none',
                        fontWeight: '600',
                        fontSize: '18px',
                    }}
                    to={profile ? '/' : '/login'}>
                    マイページ
                </Link>
            </div>
        </React.Fragment>
    );
};

export default CancelAccount;
