import React, { useEffect } from 'react';
import reState from '../../hooks/ReState';

const StripePricingTable = () => {
    const { profile } = React.useContext(reState);
    function stripeEmbed() {
        return {
            __html: `
          <stripe-pricing-table pricing-table-id="prctbl_1OLcwICHIWfiAkgs1FvQBibN"
          publishable-key="pk_live_51MzSlDCHIWfiAkgsd6eKzZAmL1jX58Y4i0aqdFbruIWW4McmBp1diiHJo7c6lIY3N4v0OLrhEpYS251AONfrSsIf00BA1iGOSy" customer-email="${profile?.email || null}">
          </stripe-pricing-table>`,
        };
    }

    return <div dangerouslySetInnerHTML={stripeEmbed()} />;
};

export default StripePricingTable;
