import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import NotFound from '../profile/NotFound';
import './CancelAccountSuccessPage.scss';
import Logo from '../../assets/logo.png';
import reState from '../../hooks/ReState';

const CancelAccountSuccessPage = () => {
    const route = useLocation();
    const {profile} = React.useContext(reState)

    React.useEffect(()=>{
        document.body.style.overflowY = 'auto';
        document.body.style.paddingBottom = '70px';
        // eslint-disable-next-line
    },[])
    return (
        <React.Fragment>
            {route?.state ? (
                <>
                    <br />
                    <br />
                    <div className="cancel-account-success-page-logo">
                        <img alt="画像" src={Logo} />
                    </div>
                    <div className="cancel-account-success-page">
                        <div className="cancel-account-success-page-title">解約申請を受け付けました。</div>
                        <br />
                        <div>
                            ご解約手続きの案内をお送りいたしました。
                            <br />
                            ご解約手続きを完了させるため、お手数ですが必ずご確認ください。
                            <br />
                            <br/>
                            注1.解約手続き案内メール記載のURLの有効期限は30分となります。
                            <br/>
                            有効期限が切れた場合は、お手数ですがはじめからやり直してください。
                            <br />
                            注2.メールサービスが提供しているフィルタリングにより迷惑メールとして扱われている場合がありますので、「迷惑メールフォルダ」や「削除フォルダ」等をご確認ください。
                        </div>
                        <br />
                        <Link
                            style={{
                                color: 'rgb(255 150 206)',
                                textAlign: 'center',
                                textDecoration: 'none',
                                fontWeight: '600',
                                fontSize: '18px',
                            }}
                            to={profile ? "/" : '/login'}>
                            マイページ
                        </Link>
                    </div>
                </>
            ) : (
                <NotFound />
            )}
        </React.Fragment>
    );
};

export default CancelAccountSuccessPage;
